import  { useEffect, useState, useRef } from "react";
import {
    MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup,
    Polyline,
    useMap,
    useMapEvents
} from 'react-leaflet'
import ReactTooltip from "react-tooltip";
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router';
import Flag from 'react-world-flags'
import "./os_labsmap.css"
import { TbCurrentLocation } from "react-icons/tb";
import html2canvas from 'html2canvas';
import { MdSaveAlt } from "react-icons/md"; 
 
import {
    Row, Col,
    Card, CardText, CardBody,
    CardHeader, CardFooter, CardTitle, CardSubtitle,
    Spinner
} from 'reactstrap';
import MarkerClusterGroup from 'react-leaflet-cluster'
import moment from 'moment';
import schools from '../schools/schools_2022_23_v3.json'

import { actions as UsersActions, selectors as UsersSelectors } from '../store/slices/users'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { selectors as AppointmentsSelector } from '../store/slices/appointments'

import "react-datetime/css/react-datetime.css";
import DateTime from 'react-datetime';
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { MdFreeCancellation } from "react-icons/md";

// Assicurati che L sia definito globalmente per il plugin
if (typeof window !== 'undefined' && window.L) {
    L = window.L;
  }

//https://stackoverflow.com/questions/65082167/obtain-a-segment-of-polyline-which-was-clicked

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

// Componente per il pulsante di stampa

const isValidLatLng = (lat, lng, origin) => {
    if (
        lat && lng &&
        typeof lat === "number" &&
        typeof lng === "number" &&
        lat >= -90 &&
        lat <= 90 &&
        lng >= -180 &&
        lng <= 180
    ) {
        return true;
    }
    console.log(`OSMD: Invalid lat: ${lat} lng: ${lng} ORIGIN: ${origin}`);
    return false;
};

const schoolMarkerIcon = new L.Icon({
    iconUrl: require("./schoolMarker.svg").default,
    iconSize: new L.Point(40, 47)
});

const labMarkerIcon = new L.Icon({
    iconUrl: require("./labMarker.svg").default,
    iconSize: new L.Point(40, 47)
});

L.Marker.prototype.options.icon = DefaultIcon;




const ExperimentContextPreview = (props) => {
    const experiment = props.experiment;
    const { t } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch();
    const [appointments, setAppointments] = useState([])
    const [amountOfAppointments, setAmountOfAppointments] = useState([0, 0])

    useEffect(() => {
        setAppointments(props.appointments?.filter((app) => {
            return app["title"] == experiment["id"]
        }));
    }, []);

    useEffect(() => {
        if (appointments == null)
            setAmountOfAppointments([0, 0])
        else {
            let pastAppointments = 0
            let nextAppointments = 0
            for (let i = 0; i < appointments.length; i++) {
                moment(appointments[i]["startDate"]).isBefore(moment.now()) ?
                    pastAppointments += 1 : nextAppointments += 1;
            }
            setAmountOfAppointments([pastAppointments, nextAppointments]);
        }
    }, [appointments])


    const renderTimelineFlags = (content) => {
        const timelines = content.timeline_pubbliche;
        if (timelines == null || timelines.length < 1) return null;
        const languages = new Set()
        for (let i = 0; i < timelines; i++) {
            if (timelines[i]["languages"] != null)
                languages.add(timelines[i]["languages"])
            else languages.add("IT");
        }
        if (languages.size < 1) { languages.add("IT"); }
        //console.log("LANGUAGES:::", languages)
        return (
            <span style={{ display: "flex", justifyContent: "flex-start" }}>
                <b>{t("Timeline disponibili in")}</b>
                {Array.from(languages).map((language, index) => {
                    return <Flag key={index} code={language} height="14" style={{ "marginTop": "2px", "marginLeft": "5px", "marginRight": "5px" }} />
                })}
            </span>
        )
    }

    const renderLiveSessionsFlags = (content) => {
        //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
        if (!content.live) return null;
        const countries = content.languages || ["IT"];
        return (
            <span style={{ display: "flex", justifyContent: "flex-start" }}>
                <b>{t("Sessioni sincrone disponibili in")}</b>
                {countries.map((language, index) => {
                    return (
                        <Flag key={index} code={language} height="14" style={{ "marginTop": "2px", "marginLeft": "5px", "marginRight": "5px" }} />
                    )
                })}
            </span>
        )
    }

    return (<Card key={props.key}>
        <CardHeader >
            <CardTitle>
                <b>{experiment["titolo"]}</b>
            </CardTitle>

        </CardHeader>
        <CardBody>

            <CardText><b>{t("Laboratorio")}:</b><br />{experiment["laboratorio"]}</CardText>
            <CardText><b>{t("Sessioni sincrone passate")}{`: `}</b>{amountOfAppointments[0]}</CardText>
            <CardText><b>{t("Sessioni sincrone in programma")}{`: `}</b>{amountOfAppointments[1]}</CardText>
            <CardText>{renderTimelineFlags(experiment)}</CardText>
            <CardText>{renderLiveSessionsFlags(experiment)}</CardText>
        </CardBody>
        <CardFooter>
            <CardText style={{ display: "flex", justifyContent: "center" }}>
                <b><a href="#" onClick={() => { dispatch(push(`/experiment/${experiment.id}`)); }} >
                    {t("Vedi scheda completa e disponibilità")}
                </a></b>
            </CardText>
        </CardFooter>
    </Card>
    )

}

const LabContextPreview = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('frontend', { useSuspense: false });
    const lab = props.lab
    ////console.log("LabContextPreview exp:", props.experiments)
    const experiments = (props.experiments && Object.values(props.experiments).filter((experiment) => {
        return experiment["labs_id"] && experiment["labs_id"].includes(parseInt(lab["lab_id"]))
    }) || []);
    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    <b>{lab["titolo"]}</b>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardTitle>
                    <b>{t("Esperimenti in catalogo")}</b>
                </CardTitle>
                {experiments.map(experiment => {
                    return (
                        <div data-id="LabContextPreview" data-tip={t("Vedi scheda completa e disponibilità")} key={experiment.id}>
                            <CardTitle>
                                <a href="#" onClick={() => { dispatch(push(`/experiment/${experiment.id}`)); }} >
                                    {experiment["titolo"]}
                                </a>
                            </CardTitle>
                            <ReactTooltip id="LabContextPreview" />
                        </div>)
                })}
            </CardBody>

        </Card>
    )
}

const SchoolContextPreview = (props) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
    const school = props.schoolContext["school"]
    const amountOfTeachers = props.schoolContext["count"]
    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    <b>{`${school["nome_istituto_riferimento"]}`}</b>
                </CardTitle>
                <CardSubtitle>
                    {school["indirizzo"] && school["comune"] && `${school["indirizzo"]},${school["comune"]}`}
                </CardSubtitle>
            </CardHeader>
            <CardBody>
                <CardText>
                    <b>{`${t("Codice Meccanografico")}: `}</b>{`${school["codice_meccanografico"] || "--"}`}
                </CardText>
                <CardText>
                    <b>{`${t("Tipo di scuola")}: `}</b>{`${school["tipo"]}`}
                </CardText>
            </CardBody>
            <CardFooter>
                <CardTitle>
                    <b>{`${t("Docenti coinvolti")}: ${amountOfTeachers}`}</b>
                </CardTitle>
            </CardFooter>
        </Card>
    )
}


const ClusteringSelector = (props) => {

    const { t } = useTranslation('frontend', { useSuspense: false });
    const handleClusterChange = (e) => {
        props.onClusterChange(e.target.checked);
    };

    return (
        <div style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            padding: '10px',
            background: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1000
        }}>
            <label>
                <input type="checkbox" checked={props.checked} onChange={handleClusterChange} />
                <span style={{ "margin": "5px" }}>{t("Raggruppa marker")} </span>
            </label>
        </div>
    );
};



export const RialeGeoNet = (props) => {
    const experiments = useSelector(CatalogSelectors.getExperiments);
    const [appointmentToLabPaths, setAppointmentToLabPaths] = useState([])
    const labs = useSelector(CatalogSelectors.getLabs);
    const [filteredLabs, setFilteredLabs] = useState({});
    const registeredUsers = useSelector(UsersSelectors.getRegisteredUsers);
    const appointments = useSelector(AppointmentsSelector.getAppointments)
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch();
    const [schoolMarkers, setSchoolMarkers] = useState([])
    const [clusterDisabledAtZoom, setClusterDisabledAtZoom] = useState(1);
    const [sessionSchoolMarkers, setSessionSchoolMarkers] = useState([])
    const [sessionLabMarkers, setSessionLabMarkers] = useState({})
    const [registeredUsersDict, setRegisteredUsersDict] = useState({})
    const [fromFilter, setFromFilter] = useState("")
    const [toFilter, setToFilter] = useState("")
    const [filterResultInfo, setFilterResultInfo] = useState("")
    const layers = [("Laboratori"), t("Scuole"), t("Laboratori e Scuole"), t("Sessioni sincrone")]
    const [selectedLayerIndex, setSelectedLayerIndex] = useState(props.onlyLabs == true ? 0 : 2)
    const defaultCenter = [42.002202972720376, 4.963713281650000];
    const defaultZoom = 3

    const mapRef = useRef(null);

    // Funzione per salvare l'immagine
    const saveMapAsImage = () => {
        html2canvas(mapRef.current, {
            useCORS: true // Necessario per i tiles della mappa
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `riale_eu_map.png`;
            link.click();
        });
    };

    const ResetViewButton = ({ center, zoom }) => {
        const map = useMap();

        const handleClick = () => {
            map.setView(center, zoom);
        };

        return (
            <>
                <IconButton onClick={() => { handleClick() }}
                    style={{
                        position: 'absolute',
                        top: '75px',
                        left: '11px',
                        padding: "5px",
                        zIndex: 400,
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                    <IconContext.Provider
                        value={{ color: "black", size: "0.88em" }}>
                        <TbCurrentLocation data-place="right"
                            data-for="resetButton" data-tip={t("Reset")}
                        />
                    </IconContext.Provider>
                </IconButton>
                <ReactTooltip id="resetButton" place="right" style={{ zIndex: 9999 }} />
            </>
        )
    }

    useEffect(() => {
        if (props.onlyLabs != true || registeredUsers?.length == 0) {
            //("DISPATCH willGetRegisteredUsers");
            dispatch(UsersActions.willGetRegisteredUsers());
        }
        else {
            //console.log("Non carico gli utenti essendo props.onlyLab=true");
        }
        setTimeout(() => { setClusterDisabledAtZoom(1) }, 0)
    }, [props.onlyLabs, registeredUsers.length]);

    // Componente personalizzato con due campi data e pulsante Reset
    const DateRangeFilter = ({ fromDate, toDate, onFromDateChange, onToDateChange }) => {
        const DATE_FORMAT = "DD/MM/YYYY";
        const { t } = useTranslation('frontend', { useSuspense: false });
        const resetFilter = () => {
            onToDateChange("");
            onFromDateChange("");
        };

        return (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <span style={{ marginTop: "8px", fontWeight: "bold" }}>{t("Da")}</span>
                <DateTime initialValue={fromDate} inputProps={{
                    disabled: false,
                    placeholder: t("gg/mm/aaaa")
                }}
                    onChange={(newDate) => onFromDateChange(newDate)}
                    dateFormat={DATE_FORMAT}

                    timeFormat={false}
                />
                <span style={{ marginTop: "8px", fontWeight: "bold" }}>{t("A")}</span>

                <DateTime initialValue={toDate} inputProps={{
                    disabled: false,
                    placeholder: t("gg/mm/aaaa")
                }}
                    onChange={(newDate) => onToDateChange(newDate)}
                    dateFormat={DATE_FORMAT}

                    timeFormat={false}
                />

                <IconButton onClick={() => { resetFilter() }}
                    style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
                >
                    <IconContext.Provider
                        value={{ color: "green", size: "1em" }}>
                        <MdFreeCancellation data-place="top"
                            data-for="dateRangeTooltip" data-tip={t("Rimuovi il filtro delle date")}
                        />
                    </IconContext.Provider>
                </IconButton>
                <ReactTooltip id="dateRangeTooltip" />
            </div>
        );
    };

    const BaseLayerEventHandler = ({ onBaseLayerChange }) => {
        const map = useMapEvents({
            baselayerchange: (e) => onBaseLayerChange(e),
        });

        return null;
    };

    const handleBaseLayerChange = (event) => {
        // console.log("Base Layer changed to:", event);
        //setFilterVisibile(!(event.name.startsWith("Laboratori (") || event.name.startsWith("Laboratories (")) )
        const newLayerIndex = layers.findLastIndex((l) => event.name.startsWith(l))
        if (newLayerIndex >= 0) {
            setSelectedLayerIndex(newLayerIndex)
        }
    };

    useEffect(() => {
        let layerInfo = `layer index not found: ${selectedLayerIndex}`
        if (selectedLayerIndex >= 0) {
            switch (selectedLayerIndex) {
                case 0:
                    layerInfo = `${t("Laboratori")} (${Object.entries(filteredLabs).length})`
                    break
                case 1:
                    layerInfo = `${t("Scuole")} (${schoolMarkers.length})`
                    break;
                case 2:
                    layerInfo = `${t("Laboratori e Scuole")} (${schoolMarkers.length + Object.entries(filteredLabs).length})`
                    break;
                case 3:
                    layerInfo = `${t("Sessioni sincrone")} (${appointmentToLabPaths?.length || 0})`
                    break;
                default:
                    layerInfo = `layer index not found: ${selectedLayerIndex}`
            }
        }
        setFilterResultInfo(layerInfo)
    }, [i18n.language, selectedLayerIndex, Object.entries(filteredLabs).length, schoolMarkers.length, appointmentToLabPaths.length])

    const getSchoolCode = (schoolInfo) => {
        const isValid = new RegExp('^[(][A-Z0-9]{10}[)]')
        if (!isValid.test(schoolInfo)) return null;
        return schoolInfo.slice(1, 11)
    }

    const buildPaths = async () => {
        //console.log(`GEOPATH: Build path su ${appointments.length} appuntamenti e ${registeredUsers.length} utenti`)
        const paths = []
        const sessionSchools = {}
        const sessionLabs = {}
        const filteredAppointments = appointments.filter(app => {
            const rowDate = new Date(app.startDate);
            const startDate = fromFilter ? new Date(fromFilter) : null;
            const endDate = toFilter ? new Date(toFilter) : null;
            const dateFilter = (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate)
            return dateFilter
        })

        for (let i = 0; i < filteredAppointments.length; i++) {
            const attendees = filteredAppointments[i]["attendees"];
            if (attendees == null || attendees.length < 1) continue;

            for (let j = 0; j < attendees.length; j++) {
                const schoolInfo = attendees[j]["school"]
                    // se si inserisce la condizione seguente si inseriscono nel conteggio
                    // delle potenziali scuole estranee alle sessioni svolte nel caso
                    // il docente abbia successivamente cambiato scuola e aggiornato il 
                    // proprio profilo 
                    //|| getAttendeeProfileSchoolInfo(attendees[j]["user"])
                    ;
                if (schoolInfo == null) continue;
                //console.log(`GEOPATH: scuola candidata: ${schoolInfo}`, appointments[i])
                let codiceMec = getSchoolCode(schoolInfo);
                if (codiceMec == null) {
                    // se non è stato trovato il codice meccanografico si potrebbe trattare di 
                    // una scuola non italiana, quindi provo a verificare se il nome della scuola
                    // è registrata uqualmente oppure no
                    if (schools[schoolInfo] == null) continue;
                    else codiceMec = schoolInfo

                }

                if (sessionSchools[codiceMec] == null)
                    sessionSchools[codiceMec] = { "school": schools[codiceMec], "count": 1 }
                else
                    sessionSchools[codiceMec]["count"] += 1

                //console.log(`GEOPATH: trovato ${codiceMec} valido`)
                const schoolCoordinates = [schools[codiceMec]["latitudine"],
                schools[codiceMec]["longitudine"]]

                const experiment = experiments[filteredAppointments[i]["title"]]
                // Un esperimento fa capo a una lista di potenziali laboratori
                // li considero tutti

                // bug patch: con le sessioni pratiche alcuni appuntamenti sono mappati
                // su lab inesistenti... li ignoro e vado avanti....
                if (experiment == null) {
                    //console.warn("Esperimento non trovato per appuntamento:", appointments[i])
                    continue;
                }
                //console.log("DBAP: Appointment:", appointments[i]);
                for (let p = 0; p < experiment["labs_id"].length; p++) {
                    const lab_id = experiment["labs_id"][p];

                    // lab id di uno specifico esperimento 
                    sessionLabs[lab_id] = labs[lab_id];

                    const labCoordinates = [labs[lab_id]["coordinates"][0],
                    labs[lab_id]["coordinates"][1]]
                    paths.push({ "positions": [schoolCoordinates, labCoordinates], experiment })
                    //console.log("GEO FOUND PATH labs_id:",experiment["labs_id"], paths.length);   

                }
            }
        }
        //console.log(`GEOPATH: Build path validi trovati: ${paths.length}`)
        setSessionSchoolMarkers(Object.values(sessionSchools));
        setSessionLabMarkers(sessionLabs);
        setAppointmentToLabPaths(paths)
    }




    useEffect(() => {
        //console.log("GEO appointments:", appointments)
        if (appointments != null && registeredUsers) {
            //console.log("OSMD: buildPathsss tot appointments:", appointments.length)
            buildPaths();
        }

        /*
        else
        {
            setAppointmentToLabPaths([]);
        setSessionLabMarkers({ });
        setSessionSchoolMarkers([]);
        }
        */

    }, [appointments, registeredUsersDict, fromFilter, toFilter]);

    useEffect(() => {
        if (labs == null) { setFilteredLabs({}) }
        else {

            const newFilteredLabs = Object.fromEntries(
                Object.entries(labs).filter(([key, lab]) => {
                    const rowDate = new Date(lab.create_date);
                    const startDate = fromFilter ? new Date(fromFilter) : null;
                    const endDate = toFilter ? new Date(toFilter) : null;
                    const dateFilter = (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate)

                    return dateFilter
                })
            );
            setFilteredLabs(newFilteredLabs)
        }
    }, [labs, fromFilter, toFilter])

    useEffect(() => {
        const registeredSchools = {}
        if (registeredUsers != null) {
            const registeredTeachers = registeredUsers.filter((user) => {
                const rowDate = new Date(user.create_date);
                const startDate = fromFilter ? new Date(fromFilter) : null;
                const endDate = toFilter ? new Date(toFilter) : null;
                const dateFilter = (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate)
                return dateFilter && user["type"] == "teacher"
            })

            //console.log("OSMD: registeredTeachers build schoolMarkers:", registeredTeachers);
            const regUsersDict = {}
            for (let i = 0; i < registeredTeachers.length; i++) {
                try {
                    const school = registeredTeachers[i]["school"]
                    regUsersDict[registeredTeachers[i]["id"]] = registeredTeachers[i]

                    let codiceMec = getSchoolCode(school);
                    ////console.log("GEOPATH: SCHOOLS COD MEC:", codiceMec)
                    //if (codiceMec == null || schools[codiceMec] == null) continue;
                    if (codiceMec == null) {
                        // se non è stato trovato il codice meccanografico si potrebbe trattare di 
                        // una scuola non italiana, quindi provo a verificare se il nome della scuola
                        // è registrata uqualmente oppure no
                        if (schools[school] == null) {
                            console.log("FSM: Scuola non trovata;", school);
                            continue;
                        }
                        else {
                            //console.log("FSM: Scuola straniera trovata con codice:", school)
                            codiceMec = school
                        }
                    }

                    if (registeredSchools[codiceMec] == null)
                        registeredSchools[codiceMec] = { "school": schools[codiceMec], "count": 1 }
                    else {
                        registeredSchools[codiceMec]["count"] += 1
                    }
                    //console.log(`OSMD: ${codiceMec} count: ${registeredSchools[codiceMec]["count"]}`);
                } catch (error) {
                    console.log("OSMD: eccezione in build schoolMarkers", error);
                    continue;
                }

            }
            // aggiungo anche le scuole che sono state usate
            setSchoolMarkers(Object.values(registeredSchools));
            //("OSMD: registeredUsers:", registeredUsers);
            //console.log("OSMD: registeredSchools:", Object.values(registeredSchools));
            // aggiorno il dizionario degli utenti che mi serve per intercettare
            // la scuola di titolarità come default per le sessioni sincrone
            setRegisteredUsersDict(regUsersDict);
        }
        else setSchoolMarkers([])
    }, [registeredUsers, fromFilter, toFilter]);
    //[40.11511479841725, 9.032469344181852] -> 8
    //[42.002202972720376,9.953613281250002] -> 4

    const renderToolbar = () => {
        return (<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div style={{display:"flex"}}>
            <DateRangeFilter fromDate={fromFilter} toDate={toFilter} onFromDateChange={(startDate) => {
                setFromFilter(startDate);
                //console.log("startDate:", startDate)
            }} onToDateChange={(endDate) => {
                setToFilter(endDate);
                //console.log("endDate:", endDate)
            }} />
             <IconButton onClick={(ev)=>{saveMapAsImage()}}
                    style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
                >
                    <IconContext.Provider
                        value={{ color: "green", size: "1em" }}>
                        <MdSaveAlt data-place="top"
                            data-for="downloadMapTooltip" data-tip={t("Scarica la mappa")}
                        />
                    </IconContext.Provider>
                    <ReactTooltip id="downloadMapTooltip"/>
                </IconButton>
              
            </div>
            <span><b>{filterResultInfo}</b></span>
        </div>)
    }
    const renderBody = () => {
        return (
            props.onlyLabs == true || (registeredUsers.length > 0) ?
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {!props.filterHidden && renderToolbar()}
                    <div ref={mapRef}>
                        <MapContainer style={{ maxHeight: props.maxHeight || "100vh" }}
                            center={defaultCenter} zoom={defaultZoom}
                            scrollWheelZoom={true}>
                            <BaseLayerEventHandler onBaseLayerChange={handleBaseLayerChange} />
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LayersControl key={clusterDisabledAtZoom} position="topright">
                                <LayersControl.BaseLayer checked={props.onlyLabs == true || selectedLayerIndex == 0}
                                    key={`layer_labs ${i18n.language}_${Object.entries(filteredLabs).length}`}
                                    name={`${t("Laboratori")} (${Object.entries(filteredLabs).length})`}>
                                    <MarkerClusterGroup

                                        chunkedLoading
                                        disableClusteringAtZoom={clusterDisabledAtZoom}
                                    >
                                        {filteredLabs && Object.entries(filteredLabs).map((entry) => (

                                            (entry[1]["coordinates"] && (!props.labsId || props.labsId.includes(entry[1].lab_id)) &&
                                                isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "filteredLabs") &&
                                                <Marker key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                                    <Popup minWidth="400" maxHeight="auto">
                                                        <LabContextPreview experiments={experiments} lab={entry[1]} />
                                                    </Popup>
                                                </Marker>
                                            )
                                        ))}
                                    </MarkerClusterGroup>
                                </LayersControl.BaseLayer>
                                {props.onlyLabs != true &&
                                    <LayersControl.BaseLayer checked={selectedLayerIndex == 1}
                                        key={`layer_schools  ${i18n.language} ${schoolMarkers.length}`}
                                        name={`${t("Scuole")} (${schoolMarkers.length})`}>
                                        <MarkerClusterGroup
                                            disableClusteringAtZoom={clusterDisabledAtZoom}
                                            chunkedLoading
                                        >
                                            {schoolMarkers && schoolMarkers.map((school, index) => {
                                                return (
                                                    school["school"] && isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"], "schools") &&
                                                    <Marker key={`${school["school"]["codice_meccanografico"]}_${index}`}
                                                        icon={schoolMarkerIcon}
                                                        position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                        <Popup minWidth="400" maxHeight="auto">
                                                            <SchoolContextPreview schoolContext={school} />
                                                        </Popup>
                                                    </Marker>
                                                )
                                            })

                                            }
                                        </MarkerClusterGroup>
                                    </LayersControl.BaseLayer>

                                }

                                {props.onlyLabs != true &&
                                    <LayersControl.BaseLayer checked={selectedLayerIndex == 2}
                                        key={`layer_labs_schools ${i18n.language} ${schoolMarkers.length + Object.entries(filteredLabs).length}`}
                                        name={`${t("Laboratori e Scuole")} (${schoolMarkers.length + Object.entries(filteredLabs).length})`}>
                                        <MarkerClusterGroup
                                            disableClusteringAtZoom={clusterDisabledAtZoom}
                                            chunkedLoading
                                        >
                                            {schoolMarkers && schoolMarkers.map((school, index) => {
                                                return (
                                                    school["school"] && isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"]) &&
                                                    <Marker key={`${school["school"]["codice_meccanografico"]}_${index}`}
                                                        icon={schoolMarkerIcon}
                                                        position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                        <Popup minWidth="400" maxHeight="auto">
                                                            <SchoolContextPreview schoolContext={school} />
                                                        </Popup>
                                                    </Marker>
                                                )
                                            })

                                            }

                                            {filteredLabs && Object.entries(filteredLabs).map((entry) => (
                                                (entry[1]["coordinates"] && isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "filteredLabs") &&
                                                    <Marker key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                                        <Popup minWidth="400" maxHeight="auto">
                                                            <LabContextPreview experiments={experiments} lab={entry[1]} />
                                                        </Popup>
                                                    </Marker>
                                                )
                                            ))}
                                        </MarkerClusterGroup>
                                    </LayersControl.BaseLayer>
                                }


                                {props.onlyLabs != true &&

                                    <LayersControl.BaseLayer checked={selectedLayerIndex == 3}
                                        key={`layer_sessions  ${i18n.language} ${appointmentToLabPaths?.length || 0}`}
                                        name={`${t("Sessioni sincrone")} (${appointmentToLabPaths?.length || 0})`}>
                                        <LayerGroup>

                                            {
                                                appointmentToLabPaths && appointmentToLabPaths.map((appToLab, index) => {
                                                    return (
                                                        <Polyline key={`polyline_${index}`}
                                                            positions={appToLab["positions"]}
                                                            pathOptions={{ color: "red" }}
                                                        >
                                                            <Popup key={`popup_${index}`} minWidth="400" maxHeight="auto">
                                                                <ExperimentContextPreview appointments={appointments} experiment={appToLab["experiment"]} />
                                                            </Popup>
                                                        </Polyline>

                                                    )
                                                })
                                            }

                                            {sessionLabMarkers && Object.entries(sessionLabMarkers).map((entry) => (
                                                (entry[1]["coordinates"] && isValidLatLng(entry[1]["coordinates"][0], entry[1]["coordinates"][1], "sessions") &&
                                                    <Marker icon={labMarkerIcon} key={entry[0]} position={[entry[1]["coordinates"][0], entry[1]["coordinates"][1]]}>
                                                        <Popup minWidth="400" maxHeight="auto">
                                                            <LabContextPreview experiments={experiments} lab={entry[1]} />
                                                        </Popup>
                                                    </Marker>
                                                )
                                            ))}


                                            {sessionSchoolMarkers && sessionSchoolMarkers.map((school) => {
                                                return (isValidLatLng(school["school"]["latitudine"], school["school"]["longitudine"], "sessionSchool") &&
                                                    <Marker key={school["school"]["codice_meccanografico"]}
                                                        icon={schoolMarkerIcon}
                                                        position={[school["school"]["latitudine"], school["school"]["longitudine"]]}>
                                                        <Popup minWidth="400" maxHeight="auto">
                                                            <SchoolContextPreview schoolContext={school} />
                                                        </Popup>
                                                    </Marker>
                                                )
                                            })

                                            }

                                        </LayerGroup>

                                    </LayersControl.BaseLayer>}
                            </LayersControl>
                            <ClusteringSelector checked={clusterDisabledAtZoom == 20} onClusterChange={(isClusteringEnabled) => {
                                console.log("ClusteringSelector:", isClusteringEnabled)
                                setClusterDisabledAtZoom(isClusteringEnabled ? 20 : 1)
                            }} />
                            <ResetViewButton center={defaultCenter} zoom={defaultZoom} />
                            {/* <LocationMarker /> */}
                        </MapContainer></div>
                </div>
                :
                <div style={{ display: "flex", flex: "center", flexDirection: "column", justifyContent: "center" }}>
                    <center>
                        <div style={{ marginBottom: "5px", fontSize: "1.3em", fontWeight: "bold" }}>{t("Caricamento in corso...")}</div>
                        <Spinner />
                    </center>

                </div>
        )
    }

    return (
        props.hideContainer ? <> {renderBody()} </> :
            (
                <div style={{ marginRight: "10px", marginLeft: "10px" }}>
                    <Row className="m-0">
                        <Col xs="12">
                            <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
                                <CardHeader id="mapHeader" style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                                    <CardTitle tag="h5">{`${t("La nostra rete")}`}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {renderBody()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
    )

}