import "react-chat-elements/dist/main.css"
import "./ChatViewer.css"
import "./bubble.css"
import { BiSolidSend } from "react-icons/bi"
import { WiMoonFull } from "react-icons/wi";
import { FaRegThumbsUp, FaRegThumbsDown, FaChevronCircleDown } from "react-icons/fa";
import FeedbackModal from "./FeedbackComponents";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { useSelector, useDispatch } from "react-redux";
import { selectors as UiSelector, actions as UIAction } from '../../store/slices/ui'
import { GiCancel } from "react-icons/gi";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md";
import { PiMouseScrollFill, PiMouseScrollLight } from "react-icons/pi";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import {
    Card, CardBody, CardHeader, CardTitle, CardFooter,
    Spinner
} from 'reactstrap';
import { push } from 'connected-react-router';
import { Input, SystemMessage } from "react-chat-elements";
import { Button } from "reactstrap"
import useWebSocket from 'react-use-websocket';
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import { selectors as AuthSelectors } from '../../store/slices/auth'
import { actions as ChatbotActions, selectors as ChatbotSelectors } from '../../store/slices/chatbot'
import useSound from 'use-sound';
import ReactDOMServer from 'react-dom/server';
import { configuration } from "../../config";
import ReactTooltip from "react-tooltip";
import moment from 'moment';

/* Mock data 
const mockPayload = {
    "arguments": {
        "userType": "teacher",
        "currentSection": "catalog",
        "catalogExperimentId": null,
        "messages": [
           
            {
                "role": "user",
                "content": [{
                    "text": "Ciao sono Jose!"
                }]
            }
           
        ]
    }
}
*/

const UserMessageBox = (props) => {
    return (<div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className='speech-bubble-ds'>
            <span style={{ marginBottom: "135px" }}><b>{props.title}</b></span><br />
            {props.message}
            <div className="speech-bubble-ds__arrow"></div>
        </div>
    </div>
    )
}

 
    

const RialeMessageBox = (props) => {
    const regSeparator = /([^\[\]]+|\[[^\[\]]+\])/g;
    const [splittedMessage, setSplittedMessage] = useState([]);
    const [guideRefs, setGuideRefs] = useState({});
    const [catalogRefs, setCatalogRefs] = useState({});
    const [syllabusRefs, setSyllabusRefs] = useState({});
    const [hover, setHover] = useState(false);
    const dispatch = useDispatch();
    const isGuideOpen = useSelector(UiSelector.isGuideOpen);
    const [feedbackType, setFeedbackType] = useState(null)

    const setupPlatformGuideRefs = (chatRefs) => {
        if (chatRefs == null) { setGuideRefs({}); return };
        const guideRefsList = chatRefs.filter((item, index) => { return item?.metadata?.type == "platform_guide" })
        const newGuideRefs = {}
        for (let i = 0; i < guideRefsList.length; i++) {
            const refKey = `but_${guideRefsList[i]["ref"].slice(1, guideRefsList[i]["ref"].length - 1)}`
            newGuideRefs[refKey] = guideRefsList[i]
        }
        setGuideRefs(newGuideRefs)
    }

    const setupCatalogRefs = (catalogRefs) => {
        if (catalogRefs == null) { setCatalogRefs({}); return };
        const catalogRefsList = catalogRefs.filter((item, index) => { return item?.metadata?.type == "catalog" })
        const newCatalogRefs = {}
        for (let i = 0; i < catalogRefsList.length; i++) {
            const refKey = `but_${catalogRefsList[i]["ref"].slice(1, catalogRefsList[i]["ref"].length - 1)}`
            newCatalogRefs[refKey] = catalogRefsList[i]
        }
        setCatalogRefs(newCatalogRefs)
    }

    const setupSyllabusRefs = (syllabusRefs) => {
        if (syllabusRefs == null) { setSyllabusRefs({}); return };
        const syllabusRefsList = syllabusRefs.filter((item, index) => { return item?.metadata?.type == "syllabus" })
        const newSyllabusRefs = {}
        for (let i = 0; i < syllabusRefsList.length; i++) {
            const refKey = `but_${syllabusRefsList[i]["ref"].slice(1, syllabusRefsList[i]["ref"].length - 1)}`
            newSyllabusRefs[refKey] = syllabusRefsList[i]
        }
        setSyllabusRefs(newSyllabusRefs)
    }

    useEffect(() => {
        setupPlatformGuideRefs(props.references)
        setupCatalogRefs(props.references)
        setupSyllabusRefs(props.references)
    }, [])


    useEffect(() => {
        //console.log("message to be splitted:", props.message);
        //const testMessage = `${props.message} [P2] questo è un test. [P3]`
        setSplittedMessage(props.message?.split(regSeparator) || [])
    }, [props.message])



    useEffect(() => {
        setupPlatformGuideRefs(props.references)
        setupCatalogRefs(props.references)
        setupSyllabusRefs(props.references)
    }, [props.references])

    useEffect(() => {
        //console.log("References aggiornati:", guideRefs);
        const refKeys = Object.keys(guideRefs);
        for (let i = 0; i < refKeys.length; i++) {
            const buttons = document.getElementsByClassName(refKeys[i]);
            for (let j = 0; j < buttons.length; j++) {
                const pageNumber = guideRefs[refKeys[i]]["metadata"]["page"]
                buttons[j].innerHTML = `P${pageNumber}`
                buttons[j].hidden = false;
                buttons[j].style.cursor = "pointer";
                //console.log("BL: Trovato button:", j, refKeys[i]);
                // Aggiungi un listener all'evento click
                buttons[j].addEventListener('click', () => {
                    // console.log(`BL: Pulsante ${j} ${refKeys[i]} cliccato per P${pageNumber}`)
                    if (!isGuideOpen) dispatch(UIAction.didSetGuideOpen(true))
                    dispatch(UIAction.didSetGuidePageNumber(pageNumber))
                });
            }
        }

        return () => {
            const refKeys = Object.keys(guideRefs)
            for (let i = 0; i < refKeys.length; i++) {
                const buttons = document.getElementsByClassName(refKeys[i]);
                for (let j = 0; j < buttons.length; j++) {
                    // console.log("BL: Trovato button:", j, refKeys[i]);
                    // Aggiungi un listener all'evento click
                    buttons[j].removeEventListener('click', () => {
                        // console.log(`BL: Pulsante ${j} ${refKeys[i]} cliccato!`)
                    });
                }

            }
        }
    }, [guideRefs])


    useEffect(() => {
        //console.log("References aggiornati:", catalogRefs);
        const refKeys = Object.keys(catalogRefs);
        for (let i = 0; i < refKeys.length; i++) {
            const buttons = document.getElementsByClassName(refKeys[i]);
            for (let j = 0; j < buttons.length; j++) {
                console.log("CATALOG METADATA:", catalogRefs[refKeys[i]]["metadata"]);
                const experimentID = catalogRefs[refKeys[i]]["metadata"]["catalogExperimentId"]
                buttons[j].innerHTML = `E${experimentID}`
                buttons[j].hidden = false;
                buttons[j].style.cursor = "pointer";
                //console.log("BL: Trovato catalog button:", j, refKeys[i]);
                // Aggiungi un listener all'evento click
                buttons[j].addEventListener('click', () => {
                    // console.log(`BL: Pulsante ${j} ${refKeys[i]} cliccato`)
                    dispatch(UIAction.didSetGuideOpen(false))
                    dispatch(push(`/experiment/${experimentID}`))

                });
            }
        }

        return () => {
            const refKeys = Object.keys(catalogRefs)
            for (let i = 0; i < refKeys.length; i++) {
                const buttons = document.getElementsByClassName(refKeys[i]);
                for (let j = 0; j < buttons.length; j++) {
                    // console.log("BL: Trovato button:", j, refKeys[i]);
                    // Aggiungi un listener all'evento click
                    buttons[j].removeEventListener('click', () => {
                        // console.log(`BL: Pulsante ${j} ${refKeys[i]} cliccato!`)
                    });
                }

            }
        }
    }, [catalogRefs])

    useEffect(() => {
        //console.log("References aggiornati:", syllabusRefs);
        const refKeys = Object.keys(syllabusRefs);
        for (let i = 0; i < refKeys.length; i++) {
            const buttons = document.getElementsByClassName(refKeys[i]);
            for (let j = 0; j < buttons.length; j++) {
                console.log("SYLLABUS METADATA:", syllabusRefs[refKeys[i]]["metadata"]);
                buttons[j].innerHTML = `Sillabus`
                buttons[j].hidden = true;
            }
        }
    }, [syllabusRefs])

    const renderButton = (msg) => {
        const butClass = `but_${msg.slice(1, msg.length - 1)}`
        return <button className={butClass} style={{ backgroundColor: "green", padding: "5px", border: "none", color: "white", cursor: "None" }} >
            {msg.slice(1, msg.length - 1)}
        </button>
            ;
    }

    const renderSplittedMessage = () => {
        const components = splittedMessage.map((msg, i) => {
            if (msg.slice(0, 2) != "[R") {
                //console.log("Messaggio normale:", msg);
                return msg
            }
            else {
                const conMsg = ReactDOMServer.renderToStaticMarkup(<>{renderButton(msg)}</>)
                //console.log("trovato riferimento:", conMsg)
                return conMsg
            }

        })
        return components.join(" ")
    }

    const renderFeedbackButtons = () => {
        return (<div style={{
            display: "flex", justifyContent: "flex-start",
            verticalAlign: "center", marginTop: "5px", marginBottom: "5px"
        }}>
            <IconButton
                style={{
                    padding: "5px"
                }}
                onClick={(ev) => {
                    setFeedbackType("POSITIVE")
                }}>
                <IconContext.Provider style={{ verticalAlign: 'top' }}
                    value={{ color: "blue", size: "0.8em" }}>
                    <FaRegThumbsUp />
                </IconContext.Provider>
            </IconButton>
            <IconButton
                style={{
                    padding: "5px",
                }}

                onClick={(ev) => {
                    setFeedbackType("NEGATIVE")
                }}>
                <IconContext.Provider style={{ verticalAlign: 'top' }}
                    value={{ color: "blue", size: "0.8em" }}>
                    <FaRegThumbsDown />
                </IconContext.Provider>
            </IconButton>
        </div>)
    }

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(ev) => { props.onClick?.(ev) }} style={{ display: "flex", justifyContent: "flex-start" }}>
            <div className='speech-bubble-sx'>
                <span><b>{props.title || "Claude"}</b></span><br />
                {props.loading ? (
                    <div className="loading" />) :
                    <div dangerouslySetInnerHTML={{ __html: `${renderSplittedMessage()}` }} />}
                <div className="speech-bubble-sx__arrow"></div>
                {hover && props.index != null && props.completed &&
                    <div>{renderFeedbackButtons()}</div>}
            </div>
            {
                feedbackType != null && props.completed && (
                    <FeedbackModal index={props.index}
                        onToggle={() => setFeedbackType(null)} feedbackType={feedbackType} />
                )
            }

        </div>
    )
}

export const AIChatbot = (props) => {
    const [userInput, setUserInput] = useState('');
    const stage = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "beta"
    const [soundsDisabled, setSoundsDisabled] = useState(localStorage.getItem("chat_sounds") == "off")
    const registrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
    const chatbotMessages = useSelector(ChatbotSelectors.getMessages)
    const [messages, setMessages] = useState(chatbotMessages || []);
    const isLogged = useSelector(AuthSelectors.isLogged)
    const [payload, setPayload] = useState({
        "userType": registrationProfile?.type || (isLogged ? "teacher" : "visitor"),
        "currentSection": props.currentSection || "catalog",
        "catalogExperimentId": parseInt(props.experimentId) || null,
        "messages": chatbotMessages || []
    });
    const [socketError, setSocketError] = useState(null);
    const dispatch = useDispatch();
    const soundUrlSend = '/sounds/chatMessageNotifySend.mp3';
    const soundUrlReceive = '/sounds/chatMessageNotifyReceive.mp3';
    const [isWaitingResponse, setWaitingResponse] = useState(false);
    const userAttributes = useSelector(ProfileSelectors.getProfile)
    const chatRefs = useRef({});
    const [currentTransaction, setCurrentTransaction] = useState(-1);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isScrollEnabled, setScrollEnabled] =  useState(localStorage.getItem("autoscroll_enabled") != "on")
    const [canSendRequest, setCanSendRequest] = useState(true);
    const toggleAssistantOpen = () => { dispatch(UIAction.toggleAssistantOpen()) }
    const { t } = useTranslation('frontend', { useSuspense: false });
    const [socketUrl, setSocketUrl] = useState(configuration[stage]["chatbotSocketUrl"])
    const [playSend] = useSound(
        soundUrlSend,
        { volume: soundsDisabled ? 0 : 0.5 }
    );

    // Funzione per generare il contenuto HTML
    const  generateHTML = (messages) => {
        let htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Conversazione</title>
    </head>
    <body>
        <div>`;

            messages.forEach(message => {
                console.log("Chat message:", message)
                //const role = message.role === "assistant" ? "Claude" : (userAttributes?.given_name || t("Guest"));
                const backgroundColor =  message.role === "assistant" ? "white" : "#007bff"
                const textColor = message.role === "assistant" ? "black" : "white"
                htmlContent += `
            <div style="color:${textColor}; padding:8px;background-color:${backgroundColor}">
                <p>${message.content[0].text}</p>
            </div>`;
            });

        htmlContent += `
    </div>
</body>
</html>`;
        return htmlContent;
    }

    const getFileName = () => {
      
        const now_date = moment(moment.now()).format("DD_MM_YY")
        const now_time = moment(moment.now()).format("HH:mm")
        return `riale_eu_chat_${now_date}_h_${now_time}`;
      }
    // Funzione per scaricare il file
    const downloadFile = (content, filename, contentType) => {
        const blob = new Blob([content], { type: contentType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
    }

 

    const [playReceive] = useSound(
        soundUrlReceive,
        { volume: soundsDisabled ? 0 : 0.5 }
    );

    const handleScrollToBottom = () => {
        const chatBox = document.getElementById('chatBox');
        chatBox.scrollTo({ top: chatBox.scrollHeight, behavior: 'smooth' });
        //setTimeout(() => { setShowScrollButton(false); }, 100)
        // Nasconde il pulsante dopo lo scroll
    };

    useEffect(() => {
        const chatBox = document.getElementById('chatBox');
        let isMounted = true;
        const handleScroll = () => {

            if (isMounted) {
                // Verifica se l'utente è vicino alla fine della chat
                const isAtBottom = (chatBox.scrollHeight - chatBox.scrollTop - chatBox.clientHeight) < 1;
                //console.log(`handle scroll: (scrollH-scrollTop:${chatBox.scrollHeight - chatBox.scrollTop} clientH:${chatBox.clientHeight}) `)
                setShowScrollButton(!isAtBottom);
            }
        };
        chatBox.addEventListener('scroll', handleScroll);
        // Cleanup
        return () => {
            isMounted = false;
            chatBox.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (soundsDisabled)
            localStorage.setItem("chat_sounds", "off")
        else
            localStorage.setItem("chat_sounds", "on")
    }, [soundsDisabled])

    useEffect(() => {
        if (isScrollEnabled)
            localStorage.setItem("autoscroll_enabled", "off")
        else
            localStorage.setItem("autoscroll_enabled", "on")
    }, [isScrollEnabled])


    useEffect(() => {
        const chatBox = document.getElementById('chatBox');
        // Verifica lo stato dello scroll ogni volta che i messaggi cambiano
        const checkIfUserIsAtBottom = () => {
            const isAtBottom = chatBox.scrollHeight - chatBox.scrollTop === chatBox.clientHeight;
            setShowScrollButton(!isAtBottom);
        };
        // Controlla quando cambiano i messaggi
        checkIfUserIsAtBottom();
    }, [messages, props.containerWidth]);  // Dipendenza da messages, quindi viene eseguito ad ogni aggiornamento dei messaggi



    useEffect(() => {
        //console.log("Payload chatbot:", payload);
    }, [payload])

    useEffect(() => {
        //console.log("ChatRefs: elenco:", chatRefs.current)
        //console.log("ChatRefs: currentTransaction:", currentTransaction)
        //console.log("ChatRefs last:", chatRefs.current[`transaction_${currentTransaction - 1}`])
        if (chatRefs.current && chatRefs.current[`transaction_${currentTransaction - 1}`]) {
            //console.log("ChatRefs: Scrolling to transaction:" , chatRefs.current[`transaction_${currentTransaction - 1}`])
        }

    }, [currentTransaction])

    // Use WebSocket hook
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => {
            setSocketError(null);
            setWaitingResponse(false);
            setCanSendRequest(true);
            //console.log('WSC: Connected to WebSocket server')
        },
        onClose: () => {
            //console.log('WSC: Disconnected from WebSocket server')
            setWaitingResponse(false);
            //console.log('WSC: Disconnected from WebSocket server')
        },
        onError: (event) => {
            setWaitingResponse(false);
            console.error('WSC: WebSocket error:', event)
        },
        shouldReconnect: (closeEvent) => {
            //console.log("WSC: Close event:", closeEvent); 
            return false;
        }, // Imposta il riconnessione automatica se si disconnette
        protocols: [],
        queryParams: {
            'Authorization': configuration[stage]["chatbotAuthorizationToken"], // Usa il token di autorizzazione
        }
    });

    // Process the received message from WebSocket
    useEffect(() => {
        if (lastMessage !== null) {
            //console.log("WSC: Last Message:", lastMessage);
            const message = JSON.parse(lastMessage.data);
            handleWebSocketMessage(message);
        }
    }, [lastMessage]);

    // Function to handle the WebSocket message
    const handleWebSocketMessage = (message) => {
        //console.log("WSC: arrivato messaggio:", message);
        if (message.hasOwnProperty('contentBlockDelta')) {
            // Aggiungi il testo al messaggio corrente
            //console.log(message.contentBlockDelta.delta.text);
            addMessageToCurrent(message.contentBlockDelta.delta.text);
        } else if (message.hasOwnProperty('messageStart')) {
            startNewMessage(message.messageStart.role);
        } else if (message.hasOwnProperty('messageStop')) {
            stopCurrentMessage(message.messageStop.stopReason);
        } else if (message.hasOwnProperty('metadata')) {
            //console.log('WSC:Message metadata:', message);
            addMetadataToCurrent(message.metadata);
        } else if (message.hasOwnProperty('error')) {
            console.error('WSC:Server error:', message);
            setWaitingResponse(false);
            setSocketError(message);
        } else if (message.hasOwnProperty('references')) {
            addReferencesToCurrent(message.references);
            displayReferences(message);
        }
    };

    const addMessageToCurrent = (text) => {
        setMessages(prevMessages => {
            if (prevMessages == null) {
                console.log("DEB WS: prevMessages null!")
                return []
            };

            const lastMessage = { ...prevMessages[prevMessages.length - 1] };
            if (lastMessage == null) {
                console.log("DEB WS: lastMessage null!")
                return []
            };
            lastMessage.content[0].text += text;
            const updatedMessages = [...prevMessages.slice(0, -1), lastMessage];
            return updatedMessages;
        });
        if (isScrollEnabled) handleScrollToBottom();
    };

    const addReferencesToCurrent = (references) => {
        setMessages(prevMessages => {
            if (prevMessages == null) {
                console.log("DEB WS: prevMessages null!")
                return []
            };

            const lastMessage = { ...prevMessages[prevMessages.length - 1] };
            if (lastMessage == null) {
                console.log("DEB WS: lastMessage null!")
                return []
            };
            lastMessage.references = references;

            const updatedMessages = [...prevMessages.slice(0, -1), lastMessage];
            dispatch(ChatbotActions.setMessages(updatedMessages))
            return updatedMessages;
        });

    };

    const addMetadataToCurrent = (metadata) => {
        setMessages(prevMessages => {
            if (prevMessages == null) {
                console.log("DEB WS: prevMessages null!")
                return []
            };

            const lastMessage = { ...prevMessages[prevMessages.length - 1] };
            if (lastMessage == null) {
                console.log("DEB WS: lastMessage null!")
                return []
            };
            lastMessage.chatbotVersion = metadata.chatbotVersion
            // memorizzo la fotografia del payload nel messaggio corrente per redux
            lastMessage["userType"] = registrationProfile?.type || (isLogged ? "teacher" : "visitor");
            lastMessage["currentSection"] = props.currentSection || "catalog";
            lastMessage["catalogExperimentId"] = parseInt(props.experimentId) || null;

            const updatedMessages = [...prevMessages.slice(0, -1), lastMessage];
            dispatch(ChatbotActions.setMessages(updatedMessages))
            return updatedMessages;
        });

    };

    const startNewChat = () => {
        setSocketUrl(null);
        setMessages([]);
        dispatch(ChatbotActions.removeAllMessages())
        setTimeout(() => {
            console.log("provo a riconnettermi");
            setSocketUrl(configuration[stage]["chatbotSocketUrl"])
        }, 100)
    }

    const startNewMessage = (role) => {
        setWaitingResponse(false);
        //setScrollEnabled(true);
        //console.log(`WSC:New message from ${role}`);
        setMessages(prevMessages => {
            const newMessages = [
                ...prevMessages,
                { role, content: [{ text: '' }] }
            ]

            setCurrentTransaction(newMessages.length)
            return newMessages;
        });


    };

    const stopCurrentMessage = (stopReason) => {
        //console.log('WSC:End of message. Stop reason:', stopReason);

        if (stopReason === 'end_turn') {
            //console.log("WSC: Messaggi:", messages)
            // riabilito il pulsante di invio appena mi arriva tutto il messaggio di risposta
            setCanSendRequest(true);
            playReceive();
            dispatch(ChatbotActions.setMessages(messages))
            setTimeout(() => {
                setUserInput(''); // Resetta il form
            }, 500);
        }
    };

    const displayReferences = (message) => {
        //console.log('WSC:Message References:', message);
        message.references.forEach((ref, index) => {
            //console.log(`  - ${ref.ref}: ${ref.s3Location.split('/')[3]}`);
        });
    };

    // Handle user input
    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const renderMessage = (message, index) => {
        if (message.role == "user")
            return (<UserMessageBox key={`user_msg_${index}`} title={userAttributes?.given_name || t("Guest")} message={message.content[0].text} />
            )
        else return <RialeMessageBox onClick={(ev) => {
            //setScrollEnabled(false);
        }}
            index={index}
            key={`assistant_msg_${index}`}
            references={message.references} // chatReferences
            completed={(!isWaitingResponse && canSendRequest)}
            loading={isWaitingResponse && index >= messages.length - 1}
            message={message.content[0].text} />

    }
    const handleSubmit = (e) => {
        //console.log("WSC: dentro handleSubmit:::", userInput)
        e.preventDefault();
        if (!canSendRequest || userInput.trim() === '') return;
        playSend();
        //console.log("WSC: prima di creare il updatedPayload", payload)
        // Update the payload with the user input
        const updatedPayload = {
            ...payload,
            messages: [
                ...messages,
                {
                    role: 'user',
                    content: [{ text: userInput }]
                }
            ]
        };
        //console.log("WSC: messaggio da inviare:", updatedPayload)
        setCanSendRequest(false);
        setPayload(updatedPayload);
        // da aggiungere per rispettare il formato della lista di messaggi
        setMessages(updatedPayload.messages);

        //console.log("WSC: invio messaggio:", updatedPayload)
        setWaitingResponse(true);
        sendMessage(JSON.stringify(updatedPayload)); // Send the payload to WebSocket
        //console.log('WSC: Message sent. Processing answer...');
    };

    // WebSocket connection state
    const connectionStatus = {
        0: {
            "color": "orange",
            "label": t("Connessione in corso")
        },
        1: {
            "color": "#A8ED0A",
            "label": t("Chat attiva")
        },
        2: {
            "color": "orange",
            "label": t("Disconnessione in corso")
        },
        3: {
            "color": "red",
            "label": t("Chat disattiva")
        }
    }

    useEffect(() => {
        if (readyState == 1 || readyState == 3) playReceive();
    }, [readyState])


    return (
        <Card className="mb-4" style={{ height: "84vh", paddingTop: "10px", borderColor: "#007bff" }}>
            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                <CardTitle>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                        <div style={{ display: "flex" }}>
                            <WiMoonFull data-for={"menu"} data-tip={t(connectionStatus[readyState]["label"])}
                                style={{
                                    size: "1.3em",
                                    marginTop: "7px",
                                    cursor: "pointer",
                                    color: connectionStatus[readyState]["color"],
                                }} />
                            <span style={{
                                marginLeft: "5px", color: "#FFFFFF", textDecoration: 'none',
                                fontSize: "1.2em", fontWeight: 'bolder'
                            }}>
                                {t("ai_assistant")}
                            </span>
                        </div>

                        <GiCancel data-for={"menu"} data-tip={t("Chiudi")} style={{
                            size: "1.2em",
                            marginTop: "6px",
                            cursor: "pointer",
                            color: "#FFFFFF",
                        }}
                            onClick={() => toggleAssistantOpen()} />

                    </div>
                </CardTitle>
            </CardHeader>

            <CardBody id="chatBox" style={{ overflowY: "auto" }}
                onMouseEnter={() => false && setScrollEnabled(false)}
                onMouseLeave={() => false && setScrollEnabled(true)}
                onClick={(ev) => { false && setScrollEnabled(false) }}>

                {    // CONNECTION OPEN
                    (readyState == 1 && <RialeMessageBox
                        loading={false}
                        message={t("chatbot_welcome_message", { "username": userAttributes?.given_name || "" })} />)
                }

                {messages.map((msg, index) => (
                    <div id={`transaction_${index}`} key={`transaction_${index}`} ref={el => { chatRefs.current[`transaction_${index}`] = el; }}>
                        {renderMessage(msg, index)}
                    </div>
                ))}
                {isWaitingResponse && <RialeMessageBox
                    loading={true}
                    message={""} />}


                {socketError && <SystemMessage text={`${socketError?.name || "Errore di comunicazione"}`} />}
            </CardBody>
            <CardFooter>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Input
                        value={userInput}
                        multiline={false}
                        onChange={(ev) => {
                            //console.log("Contenuto:", ev.target.value)
                            handleInputChange(ev)
                        }}
                        onSubmit={(ev) => { console.log("SUBMIT"); handleSubmit(ev) }}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                handleSubmit(ev)
                            }
                        }}
                        placeholder={t("type here")}

                        rightButtons={<div>
                            {(isWaitingResponse || !canSendRequest) ?
                                <Spinner style={{ marginTop: "12px", width: '1.2rem', height: '1.2rem' }} /> :
                                <IconButton
                                    disabled={!canSendRequest}
                                    style={{
                                        verticalAlign: 'top', marginLeft: 'auto'
                                    }}

                                    onClick={(ev) => { handleSubmit(ev) }}>

                                    <IconContext.Provider style={{ verticalAlign: 'top' }}
                                        value={{ color: "blue", size: "1.0em" }}>
                                        <BiSolidSend data-for="chatControls" data-tip={t("Invia")}  />
                                    </IconContext.Provider>
                                </IconButton>}

                            {showScrollButton &&

                                <IconButton 
                                    style={{
                                        verticalAlign: 'top', marginLeft: 'auto'
                                    }}

                                    onClick={(ev) => {  //console.log("Scroll in basso");
                                        handleScrollToBottom();
                                    }}>
                                    <IconContext.Provider style={{ verticalAlign: 'top' }}
                                        value={{ color: "blue", size: "1.0em" }}>
                                        <FaChevronCircleDown />
                                    </IconContext.Provider>
                                </IconButton>

                            }
                        </div>}
                    />
                    <div style={{ display: "flex" }}>
                        <Button color="primary" outline style={{ width: "100%", marginTop: "5px" }}
                            onClick={(ev) => { startNewChat() }}>{t("Nuova conversazione")}
                        </Button>

                        <IconButton
                            style={{
                                verticalAlign: 'top', marginLeft: 'auto'
                            }}>
                            <IconContext.Provider style={{ verticalAlign: 'top' }}
                                value={{ color: "#007bff", size: "1.0em" }}>
                                {soundsDisabled ?
                                    <MdVolumeOff data-for="chatControls" data-tip={t("Attiva/disattiva suono")}  onClick={() => { setSoundsDisabled(false) }} /> :
                                    <MdVolumeUp data-for="chatControls" data-tip={t("Attiva/disattiva suono")}  onClick={() => { setSoundsDisabled(true) }} />
                                }
                            </IconContext.Provider>
                        </IconButton>

                        <IconButton
                            style={{

                                verticalAlign: 'top', marginLeft: "-5px", paddingLeft: '0px'
                            }}>
                            <IconContext.Provider style={{ verticalAlign: 'top', marginLeft: "0px", paddingLeft: '0px' }}
                                value={{ color: "#007bff", size: "1.0em" }}>
                                {isScrollEnabled ?
                                    <PiMouseScrollFill data-for="chatControls" data-tip={t("Attiva/Disattiva scroll automatico")}  onClick={() => { setScrollEnabled(false) }} /> :
                                    <PiMouseScrollLight data-for="chatControls" data-tip={t("Attiva/Disattiva scroll automatico")} onClick={() => { setScrollEnabled(true) }} />
                                }
                            </IconContext.Provider>
                        </IconButton>
                     {true && (
                         <IconButton disabled={!chatbotMessages?.length>0}
                         style={{
                             verticalAlign: 'top', marginLeft: '-10px'
                         }}>
                         <IconContext.Provider style={{ verticalAlign: 'top' }}
                             value={{ color: `${chatbotMessages?.length>0 ? "#007bff" : "gray"}`, size: "0.8em" }}>
                             
                                 <FaDownload data-for="chatControls" data-tip={t("Scarica chat")}
                                 onClick={() => { if (chatbotMessages?.length>0)
                                 {
                                    const htmlContent = generateHTML(chatbotMessages);
                                    downloadFile(htmlContent, getFileName() , "text/html");
                                 }
                                    
                                 }} /> 
                         </IconContext.Provider>
                     </IconButton>
                     )}               
                       
                               <ReactTooltip id="chatControls"/>    
                    </div>

                </div>


            </CardFooter>
        </Card>
    );
};


