import { useState, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors as AppointmentsSelector, actions as AppointmentActions, getAppointmentById,
  Appointmentstate, isNoLongerAvailable
} from '../store/slices/appointments'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { actions as UiActions } from '../store/slices/ui';
import { actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import { Container, Row, UncontrolledAlert, Badge } from 'reactstrap';
import Flag from 'react-world-flags'
import { useTranslation } from 'react-i18next';
import { BUSY_COLOR, BOOKED_COLOR, UNBOOKED_COLOR, RESERVED_COLOR, NO_LONGER_AVAILABLE_COLOR, PARTIALLY_BOOKED_COLOR } from '../consts/colors'
import { SYNCHRONOUS_SESSION_TYPE, INTERACTIVE_SESSION_TYPE } from '../store/sagas/remotesessions';
import { canOpenAppointmentEditor, getEventColorByAppointment } from './Calendar/appointmentsUtils';
import interactiveSessionFlag from '../assets/telescope.svg'
const localizer = momentLocalizer(moment);


const AppointmentsViewer = (props: any) => {

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    //console.log("Sono nel costruttore: Carico TUTTI gli esperimenti dal catalogo");
    dispatch(CatalogActions.willLoadExperiments());
  }, [])

  useEffect(() => {
    //console.log("Carico gli esperimenti dal catalogo");
    const { experimentId } = props;

    dispatch(CatalogActions.willLoadExperiments());
    //console.log("Esperimento non specificato..carico tutti gli appuntamenti type:", props.type);
    dispatch(AppointmentActions.willLoadAppointments(
      {
        "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z",
        "type": props.type || ""
      }
    ));

  }, [props.experimentId])

  const appointmentsSel = useSelector(
    AppointmentsSelector.getAppointmentsOrInteractiveSessions(props.type))

  const experiments = useSelector(CatalogSelectors.getExperiments);

  const dispatch = useDispatch();

  const canEdit = userAttributes != null && userAttributes.groups != null &&
    userAttributes.groups.includes("editor");

  useEffect(() => {
    if (appointmentsSel != null) {
      if (props.experimentId == null) {
        setAppointments(appointmentsSel);
      }
      else
        setAppointments(appointmentsSel.filter((app: any) => { return app["title"] == props.experimentId }))
    }

  }, [appointmentsSel, props.experimentId])

  const addAppointment = (event: any) => {
    event.state = 0;
    const newAppointment = {
      "startDate": event.start, "stopDate": event.end,
      "type": props.type,
      "state": Appointmentstate.CREATE
    }
    //console.log("(addAppointment) Passo il payload :", newAppointment);
    //console.log("Valore di UiActions:", UiActions)
    dispatch(UiActions.openAppointmentEditor(newAppointment));
  }

  const editAppointment = (event: any) => {
    const appointment = getAppointmentById(appointments, event.id);
    if (canOpenAppointmentEditor(appointment, userAttributes, currentRegistrationProfile, canEdit)) {
      dispatch(UiActions.openAppointmentEditor(appointment));
    }
  }

  useEffect(() => {
    //console.log("valore corrente di experiments:", experiments);
  }, [experiments]);

  const getInteractiveDeviceById = (initialData: any, ideviceId: any) => {

    const currentExperiment = experiments[initialData?.title]
    //console.log(`AE: InitialData: on deviceId:${ideviceId}`, initialData, currentExperiment);
    if (currentExperiment == null) return { "titolo": "<Not found>", "descrizione": "N.A (2)" }
    const result = currentExperiment["interactive_devices"]?.filter((device: any) => {
      return device["id"] == ideviceId
    })
    if (result?.length > 0) return (result[0])
    else return { "titolo": "<Not found 2>", "descrizione": "N.A (2)" }
  }

  const getInteractiveDeviceId = (initialData: any) => {
    if (initialData?.type == null || !initialData.type.startsWith(INTERACTIVE_SESSION_TYPE)) return null;
    const isessionId = initialData.type.split(`${INTERACTIVE_SESSION_TYPE}__`)[1]
    //console.log("ISESSION ID:", isessionId)
    return isessionId
  }
  const renderExperimentFlags = (ev: any) => {
    //console.log("EF: ev->", ev.language, ev);
    if (ev["language"] == "Scelta libera" && experiments[ev?.title] != null && experiments[ev?.title].languages != null)
      return (
        <span style={{ display: "inline-flex", justifyContent: "flex-start" }}>
          {experiments[ev?.title].languages.map((country: any) => {
            console.log("EF: country->", country);
            return (
              <Flag code={country} height="14"
                style={{
                  "border": "1px solid white", "marginTop": "5px", "marginLeft": "4px",
                  "marginRight": "4px"
                }} />
            )
          })}</span>
      )
    else return (<Flag code={(ev["language"]) || "IT"} height="14"
      style={{
        "border": "1px solid white", "marginTop": "5px", "marginLeft": "4px",
        "marginRight": "4px"
      }} />)
  }

  const renderTitle = (ev: any) => {
    //if (ev["language"] != null) console.log("Calendar Event:", ev);

    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];

    //console.log(`richiamato render title con experiment:${eventId}`, experiment);
    return (
      props.type == INTERACTIVE_SESSION_TYPE ?
        (
          <span style={{ display: "flex", justifyContent: "flex-start" }}>
            <img src={interactiveSessionFlag} height="16px" alt="flag"
              style={{
                "border": "1px solid white", "marginTop": "4px", "marginBottom": "2px",
                "marginLeft": "4px", "marginRight": "4px"
              }} />
            {/*(experiment && experiment["titolo"]) || ev["title"]*/}
            {getInteractiveDeviceById(getAppointmentById(appointments, ev.id), getInteractiveDeviceId(getAppointmentById(appointments, ev.id)))["titolo"]}
          </span>)
        :
        (<div style={{ display: "flex" }}>
          {renderExperimentFlags(ev)}
          {(experiment && experiment["titolo"]) || ev["title"]}</div>) as any
    )
  }

  const renderTooltip = (ev: any) => {
    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];
    return (
      (experiment && experiment["titolo"]) || ev["title"]) as any
  }

  const handleEvent = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    const currentAppointment = getAppointmentById(appointments, event.id);
    //console.log("Appuntamento corrente:", currentAppointment);
    const eventColor = getEventColorByAppointment(currentAppointment, userAttributes, currentRegistrationProfile, canEdit)
    ////console.log(`Event color:${eventColor} state:${currentAppointment.state}`);
    return { style: { color: "white", backgroundColor: eventColor } }
  }
  return (
    <Container fluid>
      <Calendar
        style={{ height: "calc(100vh - 200px)" }}  
        culture={i18n.language}
        selectable={canEdit}
        popup={true}
        localizer={localizer}
        events={appointments}
        startAccessor="startDate"
        endAccessor="stopDate"
        titleAccessor={(ev) => renderTitle(ev)}
        tooltipAccessor={(ev) => renderTooltip(ev)}
        onSelectEvent={(event) => editAppointment(event)}
        onSelectSlot={(event) => addAppointment(event)}
        eventPropGetter={handleEvent}
        messages={{
          month: t("Month"), week: t("Week"), day: t("Day"), agenda: t("Agenda"), previous: t("Back"),
          today: t("Today"), next: t("Next"), showMore: (count: any) => `${t("Show more", { count })}`
        }}
      //views={{ month: true, week: MyWeek }}
      />
      {props.type == SYNCHRONOUS_SESSION_TYPE && (
        <Row>
          <UncontrolledAlert color="warning" style={{ margin: "10px", width: "100%" }}>
            {t("calendar_booking_alert")}
          </UncontrolledAlert>
        </Row>
      )}

      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BUSY_COLOR }}>{t("Occupato")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: BOOKED_COLOR }}>{t("Prenotato da me")}</Badge>
        {canEdit && (<Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: PARTIALLY_BOOKED_COLOR }}>{t("Parzialmente prenotato")}</Badge>)}
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: UNBOOKED_COLOR }}>{t("Disponibile")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: RESERVED_COLOR }}>{t("Riservato")}</Badge>
        <Badge style={{ margin: '5px', padding: '5px', color: 'white', backgroundColor: NO_LONGER_AVAILABLE_COLOR }}>{t("Non disponibile")}</Badge>
      </Row>

    </Container>
  )
}

export default AppointmentsViewer;