import  { useEffect } from "react";
import { Button, Badge } from "reactstrap";
import { useReactMediaRecorder } from   "../ReactMediaRecorder" //"react-media-recorder";
import {BsDownload, BsFillRecordFill,  BsFillPauseBtnFill, BsFillStopFill, BsFillPauseFill} from "react-icons/bs";
import {GrResume} from "react-icons/gr"
import {actions as ExperimentsActions, selectors as ExperimentsSelector} from '../../store/slices/experiments';
import Blink from 'react-blink-text';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
//https://github.com/0x006F/react-media-recorder/issues/105


export class RecordingRequest  {
  static IDLE = new RecordingRequest("IDLE", "no recording (idle)")
  static START = new RecordingRequest("START", "start recording")
  static STOP = new RecordingRequest("STOP", "stop recording")
  static DOWNLOAD = new RecordingRequest("DOWNLOAD", "download recording")

  constructor(name, description) {
    this.name = name
    this.description = description
  }

}

const RialeScreenRecording = ({
  screen,
  audio,
  video,
  downloadRecordingPath,
  downloadRecordingType,
  isLoggedWithPassCode,
  hiddenButtons,
  recordingRequest,
  onScreenRecordingStatusChange
 
}) => {
   
  const { t } = useTranslation();
  const bgColor = "white" //#007bff";  // era grey
  //const fgColor = "red";
  //const buttonStyle = { margin: "5px", backgroundColor: `${bgColor}` }
  
  const dispatch = useDispatch();
  const uploadedRecordingUrl = useSelector(ExperimentsSelector.getUploadedResourceUrl)



  useEffect(()=>{
    //console.log("RSR: UploadedRecordingUrl:", uploadedRecordingUrl);
  }, [uploadedRecordingUrl])


  const RecordView = () => {
    const {
      status,
      startRecording: startRecord,
      pauseRecording: pauseRecord,
      resumeRecording: resumeRecord,
      stopRecording: stopRecord,
      mediaBlobUrl,
      clearBlobUrl
    } = useReactMediaRecorder({ screen, audio, video });

  

    useEffect(()=>{
      //console.log("RSR: New recording status:", status);
      if (status === "stopped" && mediaBlobUrl)
      {
        // UPLOADING DISABILITATO per motivi di privacy
        //  n.b: in tutti i casi l'upload va a buon fini SOLO nel
        // caso di utenti regolarmente registrati e loggati alla piattaforma
        ////console.log("RSR: RECORDING STOPPED with mediaBlobUrl:", mediaBlobUrl);
        //if (mediaBlobUrl!=null) uploadRecording();
      }
      //console.log("RSR: Status changed:", status);
      onScreenRecordingStatusChange && onScreenRecordingStatusChange(status, mediaBlobUrl);
    }, [status,mediaBlobUrl])

    useEffect(()=>{
      //console.log("RSR: RecordingRequest:", recordingRequest);
      if (recordingRequest==RecordingRequest.START)
        {startRecording()}
      else if(recordingRequest==RecordingRequest.STOP)
        {stopRecording()}
      else if (recordingRequest==RecordingRequest.DOWNLOAD)
        {downloadRecording();}
    }, [recordingRequest])
    

const startRecording = () => {
      // all'avvio di una nuova registrazione rimuoviamo il blobUrl vecchio
      // eventualmente presente
      clearBlobUrl();
      return startRecord();
    };

     const pauseRecording = () => {
      return pauseRecord();
    };

    const resumeRecording = () => {
      return resumeRecord();
    };

const stopRecording = () => {
      return stopRecord();
    };
/*
    const uploadRecording = async () =>
    {
      //console.log("RSR: uploadRecording of:", mediaBlobUrl)
      if (mediaBlobUrl)
      {
        try{
          let blob = await fetch(mediaBlobUrl).then(r => r.blob());
          Object.defineProperty(blob, 'name', {
            value: uuidv4() + '/' + "session_rec",
            writable: true
          });

          Object.defineProperty(blob, 'type', {
            value: "video/mp4",
            writable: true
          });
          
          //console.log("RSR: blob:", blob)
          dispatch(ExperimentsActions.willUploadFile(blob))
          //console.log("RSR: dispatch sent")
        }catch(e) {
            //console.log("RSR: error creating blob:", e);
        }
        
      }
     
    }

const viewRecording = () => {
      window.open(mediaBlobUrl, "_blank").focus();
    };
*/
const downloadRecording = () => {
      //console.log("RSR: downloadRecording SCREEN REC: MediaBlobUrl", mediaBlobUrl)
      //if (!mediaBlobUrl) {return};
      const pathName = `${downloadRecordingPath}.${downloadRecordingType}`;
      try {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(mediaBlobUrl, pathName);
        } else {
          // for Chrome
          const link = document.createElement("a");
          link.href = mediaBlobUrl;
          link.download = pathName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        console.error(err);
      }
    };
/*
const mailRecording = () => {
      try {
        window.location.href = `mailTo:${emailToSupport}?subject=Screen recording for an Issue number ${recordingNumber}&body=Hello%20Team,%0D%0A%0D%0A${mediaBlobUrl}`;
      } catch (err) {
        console.error(err);
      }
    };
    */
return (
      <div style={{ padding:"5px", flex: 1,  justifyContent: 'space-between', alignItems: 'center' }}>
        
         
     
        {!hiddenButtons && status && status !== "recording" && status !== "paused" && (
          <Button style={{
            marginRight: 12, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }} color="primary" onClick={() => { startRecording();
            }}><BsFillRecordFill color="red" style={{marginRight:"10px"}}
            />{t(`Avvia la registrazione`)}</Button>
        )}
          
          { !hiddenButtons && status && status === "recording" && (
           <Button  style={{
            marginRight: 12, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }}  color="primary" onClick={() => { pauseRecording();
           }}><BsFillPauseFill color="white" style={{marginRight:"10px"}}
           />{t(`Metti in pausa la registrazione`)}</Button>
          )}

          { !hiddenButtons && status && status === "paused" && (
           <Button  style={{
            marginRight: 12, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }}  color="primary" onClick={() => { resumeRecording();
           }}><GrResume color="white" style={{marginRight:"10px"}}
           />{t(`Riprendi la registrazione`)}</Button>
          )}


          { !hiddenButtons && status && (status === "recording" || status==="paused") && (
           <Button  style={{
            marginRight: 12, borderWidth: 1, borderColor: 'white',
            borderStyle: 'solid', borderRadius: 4
          }}  color="primary" onClick={() => { stopRecording();
           }}><BsFillStopFill color="red" style={{marginRight:"10px"}}
           />{t(`Arresta la registrazione`)}</Button>
          )}

            {status && status === "recording" && (
            <Badge color="danger">
              <Blink color='white' blinkTime={1.5} text={t('REC')} fontSize='20'>
                  </Blink> 
            </Badge>
          )}

          {status && status !== "recording" && (isLoggedWithPassCode || status=="paused") && (
            <Badge color="warning">
            {t(`PAUSA`)}
            </Badge>
          )}
        

          {/*mediaBlobUrl && status && status === "stopped" && (
            <Button
              size="small"
              onClick={viewRecording}
              type="primary"
              icon="picture"
              className="viewRecording margin-left-sm"
            >
              View
            </Button>
          )*/}

          {!hiddenButtons && downloadRecordingType &&
            mediaBlobUrl &&
            status &&
            status === "stopped" && (
              <Button  style={{
                marginRight: 12, borderWidth: 1, borderColor: 'white',
                borderStyle: 'solid', borderRadius: 4
              }}  color="primary" onClick={() => { downloadRecording();
               }}><BsDownload color="white" style={{marginRight:"10px"}}
               />{t(`Scarica la registrazione`)}</Button>
            )}
          {/*emailToSupport && mediaBlobUrl && status && status === "stopped" && (
            <Button
              size="small"
              onClick={mailRecording}
              type="primary"
              icon="mail"
              className="mailRecording margin-left-sm"
            >
              Email To Support
            </Button>
          )*/}
     
      </div>
    );
  };
return (
    <div className="Scren-Record-Wrapper" style={{ padding: "5px 20px" }}>
      {RecordView()}
    </div>
  );
};

//const RialeScreenRecording = withTranslation()(RialeScreenRecordingNT);
export default RialeScreenRecording;