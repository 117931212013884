import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { actions as UsersActions, selectors as UsersSelector } from '../store/slices/users'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as AuthSelectors } from '../store/slices/auth'


export const findStudentByEmail = (email, data) => {
    /**
     * Questa funzione mi dice se un dato studente con la mail passata come argomento
     * fa parte di una classe passata nell'argomento 'data' (che contiene gli allowedStudents di un docente)
     */
    // Itera sulle chiavi delle classi (ad esempio "3h", "4h")
    for (const classroom in data) {
        // Cerca se l'email esiste nella lista degli studenti di quella classe
        console.log("data[classroom]:", classroom)
        const student = data[classroom].find(student => student.email === email);
        if (student) {
            return {classroom, ...student};  // Se l'email viene trovata, ritorna il nome della classe insieme ai dati dello studente
        }
    }
    return null;  // Se l'email non viene trovata in nessuna classe, ritorna null
  }

  /*
  //Funzione che mi dice se una data mail è presente tra quelle associate al docente
  const isRegisteredWithTeacher = (email, registeredStudents) => {
     if (registeredStudents==null) return false;
     return registeredStudents.find((regStudent)=> regStudent["email"]==email)!=null;
  }
  */

  export const getAmountOfPartecipantsToContestByClass = (classroom, allowedStudents) => {
    if (allowedStudents==null || allowedStudents[classroom]==null) return 0;
    return allowedStudents[classroom.toLowerCase()]?.filter((student)=> student["contest2024"]===true)?.length || 0;
  }

const useStudents = (teacherId, teacherStudents) => {

    const [students, setStudents] = useState(null);
    const [allowedStudents, setAllowedStudents] = useState(null);

    const dispatch = useDispatch();
    const userProfileFromStore = useSelector(ProfileSelectors.getProfile)
    const userProfile = (teacherId ? {"sub" : teacherId} :  userProfileFromStore)
    const registeredStudentsFromStore = useSelector(UsersSelector.getMyStudents)
    const users = useSelector(UsersSelector.getUsers2)
    const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
    const teacherRegistrationProfile = useSelector(UsersSelector.getUserProfileAsDirector(teacherId))
    const registeredStudents = teacherStudents || registeredStudentsFromStore
   
    useEffect(()=>{
        console.log("teacherRegistrationProfile::",teacherRegistrationProfile)
    }, [teacherRegistrationProfile])


   /*
    const getAllowedStudentByMail = (email) => {
        if (allowedStudents==null || students==null) return false;
        return findStudentByEmail(email)!=null;
    }
    */

    const isEmailRegisteredWithMe = (email) => {
        return  students.map(user => user.email).includes(email); 
    }
    
// Funzione per aggiungere l'attributo isRegistered agli utenti delle classi
const addRegistrationStatus = (registeredUsers, classes) => {
    // Estrai tutte le email registrate
    const registeredEmails = registeredUsers.map(user => user.email);
    console.log("registered mails:", registeredEmails)
    // Itera su ogni classe e ogni studente per aggiungere isRegistered
    Object.keys(classes).forEach(className => {
        classes[className] = classes[className].map(student => ({
            ...student,
            isRegistered: registeredEmails.includes(student.email)
        }));
    });

    return classes;
}


    useEffect(() => {
        //console.log("REG PROFILE:", currentRegistrationProfile)
        if (teacherId==null && currentRegistrationProfile != null) {
            const allowedStudents = JSON.parse(currentRegistrationProfile.allowedStudents)
            setAllowedStudents(allowedStudents || {})
        }
    }, [teacherId,currentRegistrationProfile])

    useEffect(() => {
      
        if (teacherRegistrationProfile != null) {
            const allowedStudents = JSON.parse(teacherRegistrationProfile.allowedStudents)
            setAllowedStudents(allowedStudents || {})
        }
    }, [teacherRegistrationProfile])

    useEffect(() => {
        if (userProfile != null) {
            console.log("Carico utente con ID:", userProfile.sub)
            dispatch(UsersActions.willGetStudentsByTeacherCode(userProfile.sub));
        }
    }, [userProfile]);

    

    useEffect(() => {
        if (registeredStudents != null) {
            for (const student of registeredStudents) {
                //console.log("StudentID to be loaded:", student);
                dispatch(UsersActions.willGetUser(student["user"]))
            }
        }

    }, [registeredStudents])

    useEffect(() => {
        if (allowedStudents==null) return;
        const tStudents = []
        for (let student of registeredStudents) {
            //console.log(`Cerco student ${student["user"]} in:`, users);
            if (users[student["user"]] != null) {
                const classroom = `${student["schoolGrade"] || ""}${student["schoolSection"] || ""}`
                const isAllowed = allowedStudents[classroom.toLowerCase()]?.find((studentL) => { 
                    return studentL.email==users[student["user"]]["email"]!=null})!=null

                const studentRow = { isAllowed, ...users[student["user"]], "contest2024": student["contest2024"] === true, "classroom": classroom }
                //console.log("StudentRow:", studentRow)
                tStudents.push(studentRow)
            }
            // console.log("Students:", tStudents)
        }
        setStudents(tStudents);

    }, [users, allowedStudents]);

    useEffect(() => {
        console.log("allowed students:::", allowedStudents)
        if (allowedStudents!=null && students!=null)
        {
            setAllowedStudents(addRegistrationStatus(students, allowedStudents));
        }

    }, [students, users, teacherId]);

    return {    "isEmailRegisteredWithMe" :isEmailRegisteredWithMe,
                "registeredStudents": students, 
                "allowedStudents": allowedStudents };
}

export default useStudents;