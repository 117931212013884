import { useEffect, useState } from 'react';
import MobileContainer from '../components/mobile/MobileContainer';
import { useTranslation } from 'react-i18next';

export const MobileRialeInfoPage = (props) => {
    const [bodyContent, setBodyContent] = useState("");
    const { i18n } = useTranslation('frontend', { useSuspense: false });

    useEffect(()=>{
        console.log("Lingua:", i18n.language)
    }, [])
    useEffect(() => {
        // Usa fetch per caricare contenuto.html
        fetch(`/project_info_${i18n.language.slice(0,2)}.html`)
            .then(response => response.text())
            .then(html => {
                // Crea un elemento temporaneo per estrarre solo il body
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const body = doc.body.innerHTML; // Prendi solo il contenuto del body
                setBodyContent(body); // Aggiorna lo stato con il contenuto del body
            })
            .catch(error => console.error('Errore nel caricamento del file HTML:', error));
    }, [i18n.language]);

   
    return (
        <MobileContainer>
             <img
                    src="/catalog_banner.png"
                    style={{width:"100%", height:"auto", margin:"5px 5px 20px 5px"}}
                    alt="center"

                />
           <div style={{textAlign:"left"}} dangerouslySetInnerHTML={{ __html: bodyContent }} />
        </MobileContainer>
   
)
}