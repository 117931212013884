
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AnalyticsSessionsSelector, actions as AnalyticsSessionsActions } from '../../store/slices/passCodeUsers'
import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import DateTime from 'react-datetime';
import moment from 'moment'
import {
  Card, Alert, CardBody, 
  CardHeader, CardFooter, CardTitle, Row, Col
} from "reactstrap";
import { ActivityButton } from '../../components/ActivityButton'

import "./schoolsAnalyzer.css"
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { IoReload } from "react-icons/io5";

import { useTranslation } from 'react-i18next';
import { getContest2024Trace } from "./contestDataBuilder";
import { ContestTimelinesAnalyzer } from './ContestTimelinesAnalyzer';
import { Contest2024ExcelReport } from "./Contest2024ExcelReport"

/**
 * 
 * Algoritmo di conteggio del punteggio
 * main_tl = timeline con copertura video media massima
 * other_tl = [] // altre timeline
 * 0.75 * (copertura_media_perc(main_tl)) + 0.25 * (copertura_media_allegati(main_tl)) +
 * 0.20 * sum( 0.75 * (copertura_media_perc(other_tl)) + 0.25 * (copertura_media_allegati(other_tl)) )
 */
export const ContestSchoolsAnalyzer = (props) => {
  const dispatch = useDispatch();
  const [rebuildKey, setRebuildKey] = useState(0);
  const { t } = useTranslation('frontend', { useSuspense: false });
  const contest2024Sessions = useSelector(AnalyticsSessionsSelector.getContest2024Sessions)
  const contest2024Schema = useRef(null);
  const contest2024SchoolRows = useSelector(AnalyticsSessionsSelector.getContest2024Schools)
  const DATE_FORMAT = "DD/MM/YYYY";
  const [currentStartDateFilter, setCurrentStartDateFilter] = useState(null)
  const [currentStopDateFilter, setCurrentStopDateFilter] = useState(null)

  const handleDateStartChange = (newDate) => {
    //console.log(`selezionata DateStart: ${newDate}`);
    try {
      const newDateStart = moment(newDate, DATE_FORMAT);
      if (newDateStart.isValid()) {
        setCurrentStartDateFilter(newDateStart)
      }
      else {
        setCurrentStartDateFilter(null)
      }
    } catch (error) {
      //console.log(`Invalid DateStart: ${newDate} -> ${error}`);
      setCurrentStartDateFilter(null);
    }

  }

  const handleDateStopChange = (newDate) => {
    //console.log(`selezionata DateStop: ${newDate}`);
    try {
      const newDateStop = moment(newDate, DATE_FORMAT);
      newDateStop.set({ h: 23, m: 59 })
      //console.log(`new date stop:${newDateStop.isValid()}`, newDateStop.format())
      if (newDateStop.isValid()) {
        setCurrentStopDateFilter(newDateStop)
      }
      else {
        setCurrentStopDateFilter(null)
      }
    } catch (error) {
      //console.log(`Invalid DateStop: ${newDate} -> ${error}`);
      setCurrentStopDateFilter(null);
    }

  }
  const rebuild = () => {
    //console.log("rebuild")
    dispatch(AnalyticsSessionsActions.willBuildContest2024Schools(contest2024Schema.current))
  }

  useEffect(() => {
    //console.log("Sono nel costruttore: Carico TUTTE LE ANALYTICS SESSIONS");
    dispatch(AnalyticsSessionsActions.willLoadContest2024Sessions({ "decompress": true }));
    //dispatch(UserAction.willGetRegisteredUsers());
  }, [])

  useEffect(() => {
    recalculateAll()
    setRebuildKey(rebuildKey + 1 % 2)
  }, [contest2024Sessions, currentStartDateFilter, currentStopDateFilter])

  const recalculateAll = () => {
    if (contest2024Sessions == null) return;
    const filteredData = contest2024Sessions.filter((item) => {
      const teacherIdFilter = props.teacherId || ""
      return item["passcode"].startsWith(`contest2024trace__${teacherIdFilter}`)
    }).map(
      (item) => {
        return [item["passcode"].toLowerCase(), item["content"], item["timestamp"]]
      }
    )
    const info = getContest2024Trace(filteredData, currentStartDateFilter, currentStopDateFilter);

    contest2024Schema.current = info[0]
    const sessionsTimestamps = info[1]
    console.log("salvo sessionsTimestamps:", sessionsTimestamps);
    dispatch(AnalyticsSessionsActions.setContest2024SessionTimestamps(sessionsTimestamps))
  }

  useEffect(() => {
    //console.log("contest2024Schema.current:", contest2024Schema.current);
    if (contest2024Schema.current)
      rebuild();
  }, [contest2024Schema.current])


  const paginationOptions = {
    sizePerPageList: [{
      text: `5 ${t("sessioni")}`, value: 5
    }, {
      text: `10 ${t("sessioni")}`, value: 10
    },
    {
      text: t('Tutte le sessioni'), value: (contest2024Sessions ? contest2024Sessions.length : 100)
    }
    ], pageStartIndex: 1
  }

  const overviewFormatter = (cell, row) => {
    return (<span>
      <b>{`${t("Studenti")}: `}</b>{`${row["tot_partecipants"]} ${t("di")} ${row["tot_rolled_partecipants"]}`}
      <br></br>
      <b>{`Timelines: `}</b>{row["tot_timelines"]}
    </span>)
  }

  const contest2024SchoolSessionsColumns = [
    {
      dataField: 'id',
      text: t("id"),
      sort: true,
      hidden: true
    },

    {
      dataField: 'school',
      text: t("Scuola"),
      headerStyle: () => {
        return { width: '30%' };
      },
      sort: true,
      hidden: false
    },

    {
      dataField: 'classroom',
      text: t("classroom"),
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell, row) => {
        return `${cell.toUpperCase()}`
      },
      sort: true
    },

    {
      dataField: 'full_name',
      text: t("Docente"),
      hidden: false,
      sort: true,
      formatter: (cell, row) => {
        return `${cell} (${row.email})`
      }
    }
    ,
    {
      dataField: 'email',
      text: t("mail"),
      hidden: false,
      sort: true,
      hidden: true
    },
    {
      "dataField": "overview",
      text: t("Riepilogo"),
      formatter: overviewFormatter,
      headerStyle: () => {
        return { width: '15%' };
      },
    },

    {
      dataField: 'score',
      text: t("Punteggio"),
      hidden: false,
      sort: true,
      formatter: (cell, row) => { return `${row["score"].toFixed(2)} min.` },
      headerStyle: () => {
        return { width: '12%' };
      },
    }
  ]

  const expandRow = {
    parentClassName: 'parent-expand-foo',
    expandColumnRenderer: ({ expanded }) => (expanded ? '-' : '+'),
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ? '-' : '+'),
    renderer: row => (
      <div style={{ paddingLeft: "20px" }}>
        <ContestTimelinesAnalyzer schoolData={row} ctkey={`timelineAn_${rebuildKey}`} schema={contest2024Schema.current} />
      </div>

    ),
    showExpandColumn: true
  };

  const renderFilterPanel = () => {
    return (<div style={{
      paddingTop: "5px", paddingBottom: "10px",
      display: "flex", justifyContent: "space-between"
    }}>
      <div><b>{t("Da")}</b>
        <DateTime inputProps={{ disabled: false }}
          onChange={handleDateStartChange}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
        />
      </div>
      <div><b>{t("A")}</b>
        <DateTime inputProps={{ disabled: false }}
          onChange={handleDateStopChange}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
        />
      </div>
      <div style={{ margin: "23px" }}>
        <Row></Row>
        <ActivityButton name="buildContest2024Schools" color="primary"
          onClick={() => { rebuild() }}>{t("Ricalcola i risultati")}</ActivityButton>

      </div>

    </div>)
  }

  const renderContent = () => {
    if (contest2024SchoolRows != null && t && contest2024Schema.current != null) {
      if (contest2024SchoolRows.length > 0) return (
        <div style={{display:"flex" , flexDirection:"column"}}>
          <Alert color="secondary">
            {t("score_info")}
          </Alert>
          <BootstrapTable bootstrap4
            key={`sessions_${rebuildKey}`}
            keyField='id' data={contest2024SchoolRows || []}
            columns={contest2024SchoolSessionsColumns}
            expandRow={expandRow}
            defaultSorted={[
              {
                dataField: 'score', // Campo da ordinare
                order: 'desc', // Ordine: 'asc' o 'desc'
              },
            ]}
          />
        </div>

      )
      else return (<Alert>{t("Nessun risultato trovato")}</Alert>)
    }
    else return (<Alert>{t("Caricamento sessioni in corso")}</Alert>)
  }

  return <div>
    <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
      <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
        {props.teacherId ? <CardTitle tag="h5">
          {t("I contributi al Contest dei miei studenti")}
          {/* per farlo funzionare si deve modificare willGetUserProfileAsDirector mettendo la opzione
              di forzare il ricaricamento dal backend... */
            <IconButton
              onClick={() => { rebuild() }}
              style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
            >
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <IoReload color="white" data-place="top"
                  data-for="reload"
                  data-tip={t("Reload")}
                />
              </IconContext.Provider>
            </IconButton>
          }
        </CardTitle> :
          <CardTitle tag="h5">{t("Scuole aderenti al contest con almeno una sessione tracciata")}
            {contest2024SchoolRows && ` (${t("Totale")}:${contest2024SchoolRows.length})`}

            {
              <>
                <IconButton
                  onClick={() => { rebuild() }}
                  style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
                >
                  <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                    <IoReload color="white" data-place="top"
                      data-for="reload"
                      data-tip={t("Reload")}
                    />
                  </IconContext.Provider>
                </IconButton>
                {contest2024SchoolRows && (
                  <Contest2024ExcelReport data={contest2024SchoolRows}
                    startDate={currentStartDateFilter} stopDate={currentStopDateFilter} />
                )}

              </>
            }
          </CardTitle>
        }
      </CardHeader>
      <CardBody>
        <>
          <Row>
            <Col md={12}>
              {renderFilterPanel()}
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              {renderContent()}
            </Col>

          </Row>

        </>

      </CardBody>
      <CardFooter>
      </CardFooter>
    </Card>
  </div>
}


