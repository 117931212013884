//https://github.com/wadehrarshpreet/react-svg-draw
//https://www.npmjs.com/package/react-svg-pan-zoom
//https://www.npmjs.com/package/react-planner
//https://blog.cloudboost.io/using-html5-canvas-with-react-ff7d93f5dc76
//https://www.w3schools.com/graphics/canvas_coordinates.asp

import React from 'react';
//import labPlan from './plans/pianta_lab.png'
import labPlan from './plans/labPlan.jpeg'
//import posixJson from  './plans/iot_posix.json'
import moment from 'moment';
//https://github.com/cvdlab/react-planner

export default class RialePosixTracker extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {pos:null}
  }

  drawImageScaled = (img, ctx) => {
    var canvas = ctx.canvas ;
    var hRatio = canvas.width  / img.width    ;
    var vRatio =  canvas.height / img.height  ;
    var ratio  = Math.min ( hRatio, vRatio );
    var centerShift_x = -6; //( canvas.width - img.width*ratio ) / 2;
    var centerShift_y = 3 //( canvas.height - img.height*ratio ) / 2;  
    ctx.clearRect(0,0,canvas.width, canvas.height);
    ctx.drawImage(img, 0,0, parseInt(img.width*100/100), parseInt(img.height*100/100),
                       centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  
 }

  componentDidMount() {
    const canvas = this.refs.canvas
    this.ctx = canvas.getContext("2d")
    this.img = this.refs.image

    this.img.onload = () => {
      //this.ctx.drawImage(img, 0, 0)
      this.drawImageScaled(this.img, this.ctx);
      //this.ctx.font = "40px Courier"
      //this.ctx.fillText("POSIZIONE", 210, 75)
     
    }
    this.setState({pos:null});
  }

  updatePositions = () =>
  {
    const {posixJson} = this.props;
    const tagIds = Object.keys(posixJson.points);
    for (let i=0;i<tagIds.length;i++)
    {
      this.updatePosition(tagIds[i]);
    }
  }

  updatePosition = (tagId) =>
  { 
    const {item, posixJson,currentPositionDate} = this.props;
    const posixData = posixJson.points[tagId];
    
    /*
    let t0 = moment(series0.at(0).key())
    let diff = startTime.diff(t0);
    ////console.log(`Test della timeline: t0:${t0} startTime: ${startTime}`);
    //console.log(`DIFF:${diff} -> primoEvento: ${t0.add(diff)}`); 
    //console.log(`Data minima: ${  moment.min([startTime,t0])}`)
    // Traslo tutti gli eventi in modo che partano dallo startTime
    const series1 = series0.map(e => {
      return new TimeEvent(moment(e.key()).add(diff),  e.data());
    */
    for (let i=0;i<posixData.length;i++)
    {
      //new TimeEvent(moment(e.key()).add(moment.duration(-startOffset,'seconds')).add(diff),  e.data());
      const t0 = moment(parseInt(posixData[0][0]))
      let diff = moment(item.start_time).diff(t0);
      const translatedDate = moment(parseInt(posixData[i][0])).add(moment.duration(-item.start_offset,'seconds')).add(diff);
      ////console.log(`Data posix REALE:${moment(parseInt(posixData[i][0]))} TR: ${moment(translatedDate)} CURR ${moment(currentPositionDate)}`)
        // se il campione è precedente alla data attuale, ignoro e vado avanti
       //if (moment(parseInt(posixData[i][0])).isBefore(moment(currentPositionDate)))
       if (moment(translatedDate).isBefore(moment(currentPositionDate)))
       
       {
         continue;
       }
       // se sono qui significa che ho incontrato il primo campione in una data futura

       // se ho superato almeno un campione nel passato lo aggiorno alla posizione corrente
       if (i>0)
       {
        let pos = this.state;
        if (pos==null) pos={};

        pos[tagId] = posixData[i-1][1];
        this.setState({pos})
        //console.log(`posix::updatePosition pos(${pos} del tag ${tagId} in posizione ${i-1})`);
        //console.log(`posix: posixData[i-1]: ${posixData[i-1]}`); 
      }
       
      // se ci sono solo campioni nel futuro non disegno nulla
      else
      {
        this.setState({pos:null});
        //console.log(`posix:updatePosition pos(NULL)`);
      }
      // non appena trovato il campione valido esco dal ciclo
       break;
    }
  }

  renderPositions = () =>
  {
    if (this.ctx==null) return null;
    
    //console.log(`Posix: Canvas width:${this.ctx.canvas.width}`);
    this.ctx.clearRect(0,0,this.ctx.canvas.width , this.ctx.canvas.height);
    this.drawImageScaled(this.img, this.ctx);

    const tagIds = Object.keys(this.props.posixJson["points"]);
    ////console.log(`Posix Tracker TAGS: ${tagIds}`);
    const tagColors = ["#FF0000", "#00FF00" , "#0000FF" , "#00AAAA"];
    for (let i=0;i<tagIds.length;i++)
    {
      const tagColor = tagColors[i % tagColors.length];
      this.renderPosition(tagIds[i],tagColor);
    }
  }
  
  renderPosition = (tagId, tagColor) =>
  {
    

      const {pos} = this.state;
      ////console.log(`Pozyx: valore di pos: ${pos}`);

      if (pos!=null && pos[tagId]!=null)
      {
        const {containerWidth, containerHeight, posixJson} = this.props;
        const x = parseInt(pos[tagId][0] * containerWidth / posixJson.size[0]);
        
        // l'origine sta nel bordo inferiore sinistro della stanza
        // Pertanto adatto le coordinate y
        const y = containerHeight - parseInt(pos[tagId][1] * containerHeight / posixJson.size[1]);
       
        ////console.log(`Pozyx: [${x}, ${y}] su reali [${pos[tagId][0]}, ${pos[tagId][1]}] `)
        //const markerRadius = parseInt(this.ctx.canvas.width*this.ctx.canvas.height/20000);
        const markerRadius = parseInt(Math.max(this.ctx.canvas.width,this.ctx.canvas.height)/50);
        this.ctx.beginPath();
        this.ctx.arc(x,y,markerRadius,0,2*Math.PI);
        this.ctx.fillStyle = tagColor;
        this.ctx.fill();
      }
  }



  componentDidUpdate(prevProps, prevState)
  {
    if (prevProps.currentPositionDate!=this.props.currentPositionDate || 
      prevProps.containerWidth!=this.props.containerWidth)
    {
      this.updatePositions();
    }
  }

  
  render() {
    const {posixJson,containerWidth, containerHeight} = this.props;
    //console.log(`POSIX Container width: ${containerWidth} height: ${containerHeight}`);
    //console.log(posixJson);
    return(
      <div>
        <canvas style={{cursor:"move"}} className="handle" ref="canvas" width={containerWidth} height={containerHeight} />
        <img ref="image" src={labPlan} style={{display: "none"}} alt="Lab Plan"/>
        {this.renderPositions()}
      </div>
    )
  }
}
