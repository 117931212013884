import { useState, useEffect } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Alert,
  Input,
  Label,
  FormGroup
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { useTranslation } from 'react-i18next';


export const findStudentByEmail = (email, data) => {
  /**
   * Questa funzione mi dice se un dato studente con la mail passata come argomento
   * fa parte di una classe passata nell'argomento 'data' (che contiene gli allowedStudents di un docente)
   */
  // Itera sulle chiavi delle classi (ad esempio "3h", "4h")
  for (const classroom in data) {
    // Cerca se l'email esiste nella lista degli studenti di quella classe
    console.log(`data[classroom] for ${email}:`, data[classroom])
    const student = data[classroom].find(student => student.email == email);
    if (student) {
      return { classroom, ...student };  // Se l'email viene trovata, ritorna il nome della classe insieme ai dati dello studente
    }
  }
  return null;  // Se l'email non viene trovata in nessuna classe, ritorna null
}


const StudentTabs = ({ initialData, isDataModified, onDataChanged, onRestoreRequest, onSaveRequest, readOnly }) => {
  const [data, setData] = useState(initialData);
  const [activeTab, setActiveTab] = useState(Object.keys(initialData)?.[0] || null);
  const [isFirstLoad, setIsFirstLoad] = useState(true); // Flag per il primo caricamento
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [isContest2024agree, setContest2024agree] = useState(false);


  // Validazione per le colonne editabili
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateNotEmpty = (value) => value && value.trim().length > 0;

  // Funzione per ripristinare i dati originali (Annulla)
  const handleCancel = () => {
    if (onRestoreRequest) {
      setIsFirstLoad(true);
      onRestoreRequest()
    }
  };

  // Funzione per salvare le modifiche (Salva)
  const handleSave = () => {
    if (onSaveRequest) {
      setIsFirstLoad(true);
      onSaveRequest(); // Passa i dati modificati al componente padre
    }
  };

  useEffect(() => {
    // Se l'activeTab attuale non è più una chiave valida, impostalo sulla prima chiave disponibile
    if (!Object.keys(data).includes(activeTab)) {
      const firstKey = Object.keys(data)?.[0] || null;
      setActiveTab(firstKey);
    }
  }, [data, activeTab]);


  useEffect(() => {
    //console.log("SM:: initialData:", initialData)
    //console.log("SM:: Data:", data)
    //console.log("SM:: Data!=initialData:", JSON.stringify(data) != JSON.stringify(initialData))
    if (!isFirstLoad) {
      onDataChanged(data);
    }
  }, [data, isFirstLoad]);

  useEffect(() => {
    //console.log("ST: initialData:", initialData);
    setData(initialData);
  }, [initialData]);  // Rileva i cambiamenti nei dati iniziali


  const removeRow = (key, email) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[key] = updatedData[key].filter((row) => row.email !== email);

      if (updatedData[key].length === 0) {
        delete updatedData[key];
      }
      setIsFirstLoad(false)
      return updatedData;
    });
  };

  // Funzione che rileva le modifiche in una cella e imposta lo stato
  const handleCellEdit = (columnName, newValue, rowIndex, columnIndex, oldValue, row) => {
    if (newValue !== oldValue) {
      setIsFirstLoad(false)
      //setIsDataModified(true);  // Imposta a true quando viene modificato il valore
    }
  };

  const columns = (key) => [
    {
      dataField: 'family_name',
      text: t('Cognome'),
      sort: true,
      editable: !readOnly,
      validator: (newValue) =>
        validateNotEmpty(newValue)
          ? true
          : {
            valid: false,
            message: t("Campo obbligatorio")
          }
    },
    {
      dataField: 'given_name',
      text: t('Nome'),
      sort: true,
      editable: !readOnly,
      validator: (newValue) =>
        validateNotEmpty(newValue)
          ? true
          : {
            valid: false,
            message: t("Campo obbligatorio")
          }
    },

    {
      dataField: 'isRegistered',
      text: t('Registrato'),
      headerStyle: () => {
        return { width: '6%' };
    },
      editable: false,
      hidden: false,
      formatter: (cell, row) => {
        return (<div style={{ display: "flex", justifyContent: "flex-start" }}>
          {cell ? t("Si") : t("No")}
        </div>)
      }
    },

    {
      dataField: 'email',
      text: t('Email'),
      sort: true,
      editable: !readOnly,
      validator: (newValue) =>
        validateEmail(newValue)
          ? true
          : {
            valid: false,
            message: t("Formato non valido")
          }
    },
    {
      dataField: 'contest2024',
      text: 'Contest 2024',
      editable: false,
      hidden: true,
      formatter: (cell) => (cell ? 'Sì' : 'No')
    },
   
    {
      dataField: 'action',
      text: t('Azione'),
      headerStyle: () => {
        return { width: '10%' };
    },
      editable: false,
      hidden: readOnly,
      formatter: (_, row) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button key={i18n.language}
            color="danger"
            size="sm"
            onClick={() => removeRow(key, row.email)}
          >
            {t("Rimuovi")}
          </Button>
        </div>

      )
    }
  ];


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Mostra l'alert se i dati sono stati modificati, ma non durante il primo caricamento */}
      {
        (data == null || Object.keys(data).length < 1) &&
        <Alert>
          {t("Non è stato inserito alcuno studente")}
        </Alert>

      }

      <Nav tabs>
        {Object.keys(data).map((key) => (
          <NavItem key={key}>
            <NavLink
              style={activeTab === key ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

              onClick={() => setActiveTab(key)}
            >
              {`${key.slice(0, 1)}°${key.slice(1).toUpperCase()}  (${data[key].length})`}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {Object.keys(data).map((key) => (
          <TabPane tabId={key} key={key}>
            <BootstrapTable
              key={i18n.language}
              keyField="email"
              data={data[key]}
              columns={columns(key)}
              bordered={true}
              bootstrap4
              striped
              hover
              condensed
              cellEdit={cellEditFactory({
                mode: 'dbclick',
                blurToSave: true,
                beforeSaveCell: handleCellEdit,
                afterSaveCell: (oldValue, newValue, row, column) => {
                  if (oldValue == newValue) return;
                  // Aggiorna i dati quando una cella viene modificata
                  setData((prevData) => {

                    const updatedData = { ...prevData };
                    updatedData[key] = updatedData[key].map((item) =>
                      item.email === row.email ? { ...item, [column.dataField]: newValue } : item
                    );
                    return updatedData;
                  });
                }
              })}
            />
          </TabPane>
        ))}
      </TabContent>



      {isDataModified && (
        <Alert color="info" style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}>
          <div style={{ marginBottom: "20px" }}>
            {t("Ci sono modifiche da salvare")}
          </div>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0" check>
            <Input
              id="contest2024"
              type="checkbox"
              value={isContest2024agree}
              onChange={(e) => setContest2024agree(e.target.checked)}
            />
            {' '}
            <Label for="contest2024">
              {t("contest2024_autocertificazione")}
            </Label>
          </FormGroup>
          <div style={{ marginTop: "10ox", display: "flex", justifyContent: "flex-end" }}>
            <Button color="secondary" size="sm" onClick={handleCancel} style={{ marginRight: '10px' }}>
              {t("Annulla")}
            </Button>
            <Button color="primary" disabled={!isContest2024agree} size="sm" onClick={handleSave}>
              {t("Salva")}
            </Button>
          </div>
        </Alert>
      )}
    </div>)

};


export default StudentTabs;

