import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as VideoTranscriptionsActions,
  selectors as VideoTranscriptionsSelectors
} from '../../store/slices/transcription'
import { Alert, Badge, ButtonGroup, ButtonToolbar, } from 'reactstrap';
import { FaDownload } from "react-icons/fa";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import { ActivityButton } from '../ActivityButton'
import { selectors as ProfileSelectors } from '../../store/slices/profile'

const VideoTranscriptionPanel = ({ videoItem, experimentId, onTranscriptionSelected }) => {


  const dispatch = useDispatch();
  const videoTranscriptions = useSelector(VideoTranscriptionsSelectors.getVideoTranscriptions)
  const { t } = useTranslation('frontend', { useSuspense: false });
  const isEditor = useSelector(ProfileSelectors.isEditor)

  const STATUS_COLOR = {
    TRANSCRIPTION_QUEUED: "warning",
    TRANSCRIPTION_IN_PROGRESS: "warning",
    TRANSCRIPTION_FAILED: "danger",
    PROCESSING_IN_PROGRESS: "warning",
    PROCESSING_FAILED: "danger",
    COMPLETED: "success"
  }

  const linkFormatter = (label) => (cell, row) => {

    if (cell == null) return (<span>-</span>)
    else
      return (
        <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
          {
            (cell == null) ? (<div style={{display:"flex", 
            justifyContent:"center", alignContent:"center"}} className="text-center">
              <div>{`-`}</div>
              </div>) :
              <a href={`${cell}`}>{label} </a>
          }
        </div>)
  }

  const statusFormatter = (cell, row) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Badge color={STATUS_COLOR[cell] || "dark"}>{cell}</Badge>
      </div>)
  }


  const actionsFormatter = (cell, value) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

        <ButtonToolbar>
          <ButtonGroup>
            <FaDownload data-tip={t("Importa")}
              cursor={value.transcriptionStatus == "COMPLETED" ?  "pointer" : "default"}
              size={20}
              style={{ margin: "5px", paddingTop: "5px" }}
              color={`${value.transcriptionStatus == "COMPLETED" ? "green" : "red"}`}
              onClick={(ev) => {
                if (value.transcriptionStatus == "COMPLETED")
                 {
                  console.log("Transcription to be imported:", value);
                  onTranscriptionSelected?.(value)
                 }
              }}
            >
            </FaDownload>
          </ButtonGroup>
        </ButtonToolbar>
        <ReactTooltip />
      </div>
    )
  }
  // Definizione delle colonne
  const columns = [
    {
      dataField: 'transcriptionJobName',
      text: '',
      hidden: true
    },
    {
      dataField: 'creationDate',
      text: `${t("Data di creazione")}`,
      sort: true,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:mm:ss'),
      headerStyle: () => {
        return { width: '20%' };
      },
      headerAlign: 'center'
    },
    {
      dataField: 'subtitlesUrl',
      text: `${t("Sottotitoli")} VTT URL (orig)`,
      formatter: linkFormatter("Url (orig)"),
      headerAlign: 'center',
      sort: true,
    },
    {
      dataField: 'subtitlesUrl_IT',
      text: `${t("Sottotitoli")} VTT URL (IT)`,
      sort: true,
      formatter: linkFormatter("Url (IT)"),
      headerAlign: 'center',
    },
    {
      dataField: 'subtitlesUrl_EN',
      text: `${t("Sottotitoli")} VTT URL (EN)`,
      headerAlign: 'center',
      sort: true,
      formatter: linkFormatter("Url (EN)"),
    },
    {
      dataField: 'transcriptionStatus',
      text: `${t("Stato")}`,
      sort: true,
      formatter: statusFormatter,
      headerAlign: 'center',

    },
    {
      dataField: 'actions',
      text: t('azioni'),
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8%', alignItems: "center" };
      },
      headerAlign: 'center',
    },
  ];


  useEffect(() => {
    // carico la lista di trascrizioni del video passato come argomento
    loadVideoTranscriptions();
  }, [])

  const loadVideoTranscriptions = () => {
    if (videoItem != null)
      dispatch(VideoTranscriptionsActions.willLoadVideoTranscriptions(videoItem.id));
  }

  const generateVideoTranscriptions = () => {
    const payload = { "experimentId": experimentId, "videoItemId": videoItem.id }
    dispatch(VideoTranscriptionsActions.willGenerateVideoTranscription(payload))
  }

  
  return <div style={{ display: "flex", flexDirection: "column" }}>
    {/*
    <span>Esperimento ID: {experimentId}  Trovate: {videoTranscriptions.length}  </span>
    <span>VideoItem ID: {videoItem.id}</span>
    */}
    {videoTranscriptions.length>0 ? 
    <BootstrapTable
      bootstrap4
      keyField='transcriptionJobName'
      data={videoTranscriptions}
      columns={columns}
      defaultSorted={[{ dataField: "creationDate", order: "desc" }]}
      pagination={paginationFactory()}
      striped
    /> :
    <Alert color="info" className="text-center">
    {t("alertNoVideoTranscriptions")}
   </Alert>}

   {!isEditor && (
     <Alert color="warning" className="text-center">
     {t("alertVideoTranscriptionsForbidden")}
    </Alert>
   )}

    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
      <ActivityButton disabled={!isEditor} color="primary" name="generateVideoTranscription" onClick={(ev) => {
        generateVideoTranscriptions();
      }}>{t("Genera nuova trascrizione")}</ActivityButton>
      <ActivityButton color="primary" name="refreshVideoTranscriptionStatus" onClick={(ev) => {
        loadVideoTranscriptions();
      }}>{t("Aggiorna")}</ActivityButton>
    </div>
  </div>
}

export default VideoTranscriptionPanel;