import { Component, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-duration-format';

import { IconContext } from "react-icons";
import { FaPlay, FaPause, FaEdit, FaRegClock, FaCopy, FaPaste, FaUndo, FaRedo, FaPhotoVideo } from 'react-icons/fa';
import { LuAlignStartVertical, LuAlignEndVertical } from 'react-icons/lu'
import { AiOutlineFileSearch, AiFillStepBackward, AiFillStepForward, AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai'
import { FiZoomIn, FiZoomOut, FiBookmark } from "react-icons/fi";
import { BiLinkAlt } from "react-icons/bi";
import { GoScreenNormal } from "react-icons/go";
import { Badge, Input } from 'reactstrap';

import { BiDockTop, BiDockBottom } from 'react-icons/bi'
import IconButton from '@material-ui/core/IconButton';

import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { TraceEvent } from './TimelineWatcher';
import { TimeDurationInput } from 'react-time-duration-input';
import { TimelineProgress } from './VideoThumbnailsProgress';
import { getFormattedTime, getDurationInSeconds } from './Utils'
import "./TimelineToolbar.css"

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';


const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
//const DATE_FORMAT = "DD/MM/YYYY";
//const TIME_FORMAT = "HH:mm:ss";
//const DAY_TIME_FORMAT = "DD HH:mm:ss";

class TimelinePlayerNT extends Component {


    constructor(props) {
        super(props);
        this.state = { isInvalidDate: false, originalDate: this.props.currentPositionDate };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isPlaying != prevProps.isPlaying) {
            ReactTooltip.rebuild();
        }
    }

    toggleTimeCursor = () => {
        this.props.onToggleTimeCursor();
    }

    toggleAttachments = () => {
        this.props.onToggleAttachments();
    }

    toggleItemsNavigator = () => {
        this.props.onToggleItemsNavigator();
    }

    addBookmark = () => {
        this.props.onAddBookmark();
    }

    onGoHomeRequest = () => {
        this.props.onGoHomeRequest()
    }

    onGoToEndRequest = () => {
        this.props.onGoToEndRequest()
    }

    togglePlay = () => {

        if (this.props.isPlaying) {

            this.props.onEventToWatch && (this.props.onEventToWatch(TraceEvent.PLAYING_STATUS_CHANGE_REQUEST,
                { "newPlayingRequest": false }));
            //console.log("WATCHER DEBUG chiedo di andare in pausa e faccio playing=false")
            this.props.onPause();
        }

        else {
            this.props.onEventToWatch && (this.props.onEventToWatch(TraceEvent.PLAYING_STATUS_CHANGE_REQUEST,
                { "newPlayingRequest": true }));
            //console.log("WATCHER DEBUG chiedo di andare in play e faccio playing=true")
            this.props.onPlay();
        }

    }


    handleDateChanged = (currentDate) => {
        //console.log(`NEW DATE:${currentDate}`);
        // se il valore corrente della data non rappresenta una data valida,
        // NON inoltro il nuovo valore alla timeline
        if (!moment(currentDate, DATE_TIME_FORMAT).isValid()) {
            this.setState({ isInvalidDate: true });
            //console.log("Inserita una data non valida");
            return;
        }
        else {
            this.props.onDateChanged(moment(currentDate, "DD/MM/YYYY HH:mm:ss"));
            this.setState({ isInvalidDate: false, originalDate: moment(currentDate, DATE_TIME_FORMAT) });
        }
    }

    /*
    handleBlur = (currentDate) => {
        const {isInvalidDate, originalDate} = this.state;
        //console.log(`Focus rilasciato dal calendar della timeline su ${currentDate} Invalid? ${isInvalidDate}`);
        //console.log(`Data valida? ${moment(currentDate, DATE_FORMAT).isValid()} `);
        if (!moment(currentDate, DATE_FORMAT).isValid())
        {  
            //console.log(`Ripristino la data ${this.props.currentPositionDate}`);
            //this.props.onDateChanged(moment(this.props.currentPositionDate));
            this.handleDateChanged(this.props.currentPositionDate)
        }
    }
    */

    isDayIncluded = (currentDate) => {
        const { items } = this.props;
        for (let index in items) {
            ////console.log(`current:${moment(currentDate)}`);
            ////console.log(`start:${items[index].start_time.startOf("day")}`);
            ////console.log(`end:${ items[index].end_time}`);
            ////console.log(" ");
            // aggiungo un secondo fittizio alla data del giorno del calendario per intercettare le eventuali intersezioni con gli items
            if (moment(currentDate).startOf('day').add(1, 'seconds').isBetween(
                moment(items[index].start_time).startOf("day"), moment(items[index].end_time))) {
                ////console.log(`Trovato item nel giorno: ${currentDate}`);
                return true;
            }

        }
        ////console.log(`Non hp trovato alcun item nel giorno: ${currentDate}`);
        return false;
    }

    renderDay = (props, currentDate, selectedDate) => {
        if (this.isDayIncluded(currentDate))
            return <td {...props}> <b>{currentDate.date()}</b></td>;
        else
            return <td {...props}> {currentDate.date()} </td>;
    }

    renderMonth(props, month, year, selectedDate) {
        return <td {...props}>{month}</td>;
    }

    renderYear(props, year, selectedDate) {
        return <td {...props}>{year % 100}</td>;
    }

    getColoredButtonStyle(enabled) {
        const buttonBgColor = (enabled ? "green" : "red"); // "#007bff"
        return { padding: "10px", margin: "4px", backgroundColor: `${buttonBgColor}` }
    }

    render() {

        const bgColor = "#007bff"; // grey
        const fgColor = "white";
        const buttonStyle = { padding: "10px", margin: "4px", backgroundColor: `${bgColor}` }

        //const {isInvalidDate,originalDate} = this.state;
        const validDate = this.props.currentPositionDate; //(isInvalidDate) ?  originalDate: this.props.currentPositionDate;
        ////console.log(`Valid date (currentPositionDate): ${validDate}`);

        const { t } = this.props;



        let buttonIcon = (this.props.isPlaying ?
            (<FaPause data-for="toolbar" data-place="top" data-tip={`${t("tl:tip_timeline_pause")} (alt+p)`} />) :
            (<FaPlay data-for="toolbar" data-place="top" data-tip={`${t("tl:tip_timeline_play")} (alt+p)`} />));


        return (

            <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', flexShrink: '0' }}>


                <TimelineProgress
                    items={this.props.items}
                    isLoading={this.props.isPlaying && this.props.areVideoLoading}
                    onDateChanged={this.props.onDateChanged}
                    firstItemStartTime={this.props.firstItemStartTime}
                    lastItemEndTime={this.props.lastItemEndTime}
                    currentPositionDate={this.props.currentPositionDate}
                    isPlaying={this.props.isPlaying}
                    onPlay={this.props.onPlay}
                    onPause={this.props.onPause}
                    width="100%" />
               

                <IconButton
                    style={this.getColoredButtonStyle(true)}
                    onClick={() => this.addBookmark()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <FiBookmark data-place="top" data-tip={`${t("tl:tip_addBookmark")} (ctrl+b)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton
                    style={this.getColoredButtonStyle(this.props.isTimeCursorVisible)}
                    onClick={() => this.toggleTimeCursor()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <FaRegClock data-place="top" data-tip={t("tl:tip_showHideTlCursor")} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton
                    style={this.getColoredButtonStyle(this.props.isItemsNavigatorPanelVisible)}
                    onClick={() => this.toggleItemsNavigator()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <AiOutlineFileSearch data-place="top" data-tip={`${t("tl:tip_showHideItemsNavigator")} (alt+b)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton className={
                    this.props.isPlaying && !this.props.isAttachmentsPanelVisible ?
                        'pulse' : ""}
                    style={this.getColoredButtonStyle(this.props.isAttachmentsPanelVisible)}
                    onClick={() => this.toggleAttachments()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <FaPhotoVideo data-place="top" data-tip={`${t("tl:tip_showHideTlAttachments")} (alt+a)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton
                    style={buttonStyle}
                    onClick={() => this.onGoHomeRequest()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <LuAlignStartVertical data-place="top" data-tip={`${t("tl:tip_gohome")} (alt+h)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton
                    style={buttonStyle}
                    onClick={() => this.onGoToEndRequest()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <LuAlignEndVertical data-place="top" data-tip={`${t("tl:tip_gotoend")} (alt+end)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton style={buttonStyle}
                    onClick={() => this.togglePlay()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        {buttonIcon}
                    </IconContext.Provider>
                </IconButton>

                <div style={{ marginLeft: "10px", display: 'inline-block' }}>
                    <TimePicker firstItemStartTime={this.props.firstItemStartTime}
                        lastItemEndTime={this.props.lastItemEndTime}
                        currentPositionDate={moment(validDate)}
                        onChange={this.handleDateChanged}
                        textStyle={{ "fontWeight": 'bold', "textAlign": "center" }}
                        showTotalTime
                    />
                </div>
                {
                    this.props.selectedItem != null &&
                    <SelectedItemPanel {...this.props} />
                }
                <ZoomPanel zoomDuration={this.props.zoomDuration}
                    onEventToWatch={this.props.onEventToWatch}
                    onZoomChanged={this.props.onZoomChanged}
                />


                {this.props.canEdit &&
                    <IconButton onClick={() => this.props.onToggleTimelineEditing()}
                        style={this.getColoredButtonStyle(this.props.isEditingEnabled)}>
                        <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                            <FaEdit data-place="top" data-tip={t("tl:tip_enableDisableEditing")} />
                        </IconContext.Provider>
                    </IconButton>
                }

                {this.props.canEdit && this.props.isEditingEnabled &&
                    <IconButton disabled={!this.props.isEditingEnabled} onClick={() => this.props.onPasteItemFromClipboard()}
                        style={this.getColoredButtonStyle(this.props.isEditingEnabled)}>
                        <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                            <FaPaste data-place="top" data-tip={`${t("tl:tip_paste")} (ctrl+v)`} />
                        </IconContext.Provider>
                    </IconButton>
                }


                {this.props.canEdit && this.props.isEditingEnabled &&
                    <IconButton disabled={!this.props.canUndo} onClick={() => this.props.onUndo()}
                        style={this.getColoredButtonStyle(this.props.canUndo)}>
                        <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                            <FaUndo data-place="top" data-tip={`${t("tl:tip_undo")} (ctrl+z)`} />
                        </IconContext.Provider>
                    </IconButton>
                }

                {this.props.canEdit && this.props.isEditingEnabled &&
                    <IconButton disabled={!this.props.canRedo} onClick={() => this.props.onRedo()}
                        style={this.getColoredButtonStyle(this.props.canRedo)}>
                        <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                            <FaRedo data-place="top" data-tip={`${t("tl:tip_redo")} (ctrl+y)`} />
                        </IconContext.Provider>
                    </IconButton>
                }

                {this.props.isPublic &&
                    <IconButton disabled={false} onClick={() => this.props.onCopyLinkToClipboard()}
                        style={this.getColoredButtonStyle(true)}>
                        <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                            <BiLinkAlt data-place="top" data-tip={`${t("tl:tip_link_copy")}`} />
                        </IconContext.Provider>
                    </IconButton>
                }
                <div  style={{marginBottom:"10px"}}>
                <ZoomSlider editorDown={true} visibleTimeStart={this.props.visibleTimeStart}
                    visibleTimeEnd={this.props.visibleTimeEnd}
                    firstItemStartTime={this.props.firstItemStartTime}
                    lastItemEndTime={this.props.lastItemEndTime}
                    zoomDuration={this.props.zoomDuration}
                    onScrollTo={this.props.onScrollTo} />
                </div>


                {/*<ReactTooltip/>*/}
            </div>
        );
    }

}

class ZoomPanelNT extends Component {

    zoomIn = () => {
        const { zoomDuration } = this.props;

        let newDuration = moment.duration(zoomDuration).add(-1, this.getDurationStep(zoomDuration))
        this.props.onZoomChanged(newDuration);
    }


    zoomOut = () => {
        const { zoomDuration } = this.props;
        let newDuration = moment.duration(zoomDuration).add(1, this.getDurationStep(zoomDuration))
        this.props.onZoomChanged(newDuration);
    }

    zoomReset = () => {
        this.props.onZoomChanged(null);
    }

    getDurationStep = (duration) => {
        if (duration.asMonths() > 1)
            return "months"
        else if (duration.asWeeks() > 1)
            return "weeks"
        else if (duration.asDays() > 1)
            return "days"
        else if (duration.asHours() > 1)
            return "hours"
        else if (duration.asMinutes() > 1)
            return "minutes"
        else return "seconds"
    }

    render() {
        const { zoomDuration, t } = this.props;
        const bgColor = "#007bff";  // era grey
        const fgColor = "white";
        const durationInfo = zoomDuration.asMilliseconds() < 60 * 60000 ?
            `${zoomDuration.format()} min.` : `${zoomDuration.format()}`
        const buttonStyle = { padding: "10px", margin: "4px", backgroundColor: `${bgColor}` }
        ////console.log(`TL duration ${zoomDuration.format()}`);
        return (
            <div style={{ display: 'inline-block', marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                <div style={{ display: 'flex', flexDirection: "column-reverse" }}>

                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", marginLeft: '5px', marginRight: '5px' }}>
                        <IconButton
                            style={buttonStyle}
                            onClick={() => this.zoomIn()}>
                            <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                                <FiZoomIn data-place="top" data-tip={t("tl:tip_zoomIn")} />
                            </IconContext.Provider>
                        </IconButton>

                        <IconButton
                            style={buttonStyle}
                            onClick={() => this.zoomOut()}>
                            <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                                <FiZoomOut data-place="top" data-tip={t("tl:tip_zoomOut")} />
                            </IconContext.Provider>
                        </IconButton>
                        <IconButton
                            style={buttonStyle}
                            onClick={() => this.zoomReset()}>
                            <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                                <GoScreenNormal data-place="top" data-tip={t("tl:tip_zoomReset")} />
                            </IconContext.Provider>
                        </IconButton>
                    </div>
                    <Badge color="primary" data-place="top" style={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px" }} data-tip={t("tl:tip_timelineWindowDuration")} >Zoom: {durationInfo}</Badge>

                </div>
                {/*<ReactTooltip/>*/}
            </div>
        )
    }
}

const SelectedItemPanel = (props) => {
    const fgColor = "white";
    const { t } = useTranslation('timeline', { useSuspense: false });
    const { selectedItem, draggingItem, itemDraggingTime } = props;

    const MAX_LEN = 40
    let itemInfoTitle = draggingItem == null ? "" : draggingItem.title;
    let itemInfoDuration = selectedItem != null ?
        //moment.utc(moment.duration(selectedItem.duration*1000.0,'milliseconds').asMilliseconds()).format("HH:mm:ss") 
        moment.utc(moment.duration(moment(selectedItem.end_time).diff(selectedItem.start_time)).asMilliseconds()).format("HH:mm:ss")
        : "N.A";
    let itemInfoStartTime = itemDraggingTime == null ? "" : moment(itemDraggingTime).format(DATE_TIME_FORMAT);
    if (itemInfoTitle === "") {
        if (selectedItem != null) {
            itemInfoTitle = selectedItem.title;
            itemInfoStartTime = moment(selectedItem.start_time).format(DATE_TIME_FORMAT);
        }
    }
    console.log();
    if (itemInfoTitle?.length > MAX_LEN)
        itemInfoTitle = `${itemInfoTitle.substring(0, MAX_LEN)}... `;

    //const formattedDate = moment(this.props.currentPositionDate).format("DD/MM/YYYY hh:mm:ss");

    const getColoredButtonStyle = (enabled) => {
        const buttonBgColor = (enabled ? "green" : "red"); // "#007bff"
        return { padding: "10px", margin: "4px", backgroundColor: `${buttonBgColor}` }
    }
    return <div style={{ marginLeft: "10px", display: 'inline-block' }}>
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <IconButton disabled={false} onClick={() => props.onDateChanged(props.selectedItem.start_time)}
                    style={getColoredButtonStyle(true)}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <AiFillStepBackward data-for="selectedItemToolbar" data-place="top" data-tip={`${t("tl:tip_goto_selected_item_start")} (alt+Page Up)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton disabled={false} onClick={() => props.onDateChanged(props.selectedItem.end_time)}
                    style={getColoredButtonStyle(true)}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <AiFillStepForward data-for="selectedItemToolbar" data-place="top" data-tip={`${t("tl:tip_goto_selected_item_end")} (alt+Page Down)`} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton disabled={props.selectedItem == null} onClick={() => props.onCopyItemToClipboard()}
                    style={getColoredButtonStyle(props.selectedItem != null)}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name", size: "0.65em" }}>
                        <FaCopy data-for="selectedItemToolbar" data-place="top" data-tip={`${t("tl:tip_copy")} (ctrl+c)`} />
                    </IconContext.Provider>
                </IconButton>
            </div>
            {itemInfoTitle !== "" &&
                (<Badge color="success">{itemInfoTitle}:
                    {/*`${getFormattedTime(this.props.firstItemStartTime, 
                        selectedItem.start_time)}`*/} (Durata:{itemInfoDuration})</Badge>)}
        </div>
        <ReactTooltip id="selectedItemToolbar" />
    </div>
}

const ZoomPanel = withTranslation()(ZoomPanelNT);

export const ToolbarPosition = {
    TOP: "TOP",
    BOTTOM: "BOTTOM",
    FLOAT: "FLOAT"
}



export const TimePicker = (props) => {

    const { firstItemStartTime, lastItemEndTime, currentPositionDate, showTotalTime, initialValue, editorDown } = props;
    const [currentTime, setCurrentTime] = useState(moment.duration(
        moment(currentPositionDate).diff(moment(firstItemStartTime))).asSeconds());
    const [editorVisible, setEditorVisible] = useState(false);
    const [timelineDuration, setTimelineDuration] = useState(getFormattedTime(firstItemStartTime, lastItemEndTime))

    useEffect(() => {
        //console.log(`TimePicker initialValue:${initialValue} ct:${currentTime}`)
    }, [])

    useEffect(() => {
        setTimelineDuration(getFormattedTime(firstItemStartTime, lastItemEndTime))
    }, [firstItemStartTime, lastItemEndTime])

    return (
        <div style={{ width: `${showTotalTime ? "235px" : "130px"}`, display: "flex", flexDirection: `${editorDown ? "column" : "column-reverse"}` }}>
            <div style={{ display: "flex", border: "1px solid black" }}>
                <Input type={"text"} style={{ fontWeight: "bold", padding: "6px", border: "0px solid black" }} readOnly
                    onClick={(ev) => {
                        setCurrentTime(initialValue || moment.duration(
                            moment(currentPositionDate).diff(moment(firstItemStartTime))).asSeconds())
                        setEditorVisible(!editorVisible);
                    }}
                    value={
                        showTotalTime ?
                            `${getFormattedTime(firstItemStartTime, currentPositionDate)} / ${timelineDuration}`
                            :
                            `${getFormattedTime(firstItemStartTime, currentPositionDate)}`
                    }
                />
                {
                    ((editorVisible && !editorDown) || (!editorVisible && editorDown)) ? (
                        <AiOutlineCaretDown size={"1.6em"} style={{
                            marginLeft: "2px", marginRight: "5px",
                            marginTop: "5px"
                        }} cursor="pointer" color='blue' onClick={(ev) => {
                            setCurrentTime(initialValue || moment.duration(
                                moment(currentPositionDate).diff(moment(firstItemStartTime))).asSeconds())
                            setEditorVisible(!editorVisible);
                        }}></AiOutlineCaretDown>)
                        : (
                            <AiOutlineCaretUp size={"1.6em"} style={{
                                marginLeft: "2px", marginRight: "5px",
                                marginTop: "5px"
                            }} cursor="pointer" color='blue' onClick={(ev) => {
                                setCurrentTime(initialValue || moment.duration(
                                    moment(currentPositionDate).diff(moment(firstItemStartTime))).asSeconds())
                                setEditorVisible(!editorVisible);
                            }}></AiOutlineCaretUp>

                        )
                }


            </div>
            {
                editorVisible && (

                    <TimeDurationInput

                        value={currentTime}
                        scale={props.scale || "s"}
                        onChange={(newValue) => {
                            //console.log("TP: onChange duration");
                            //setCurrentTime(newValue);
                            props.onChange(moment(moment(firstItemStartTime).add(newValue * 1000), "DD/MM/YYYY HH:mm:ss"));
                        }} />

                )
            }
        </div>)

}

export const ZoomSlider = (props) => {

    const { visibleTimeStart, visibleTimeEnd, zoomDuration,
        firstItemStartTime, lastItemEndTime, onScrollTo, editorDown } = { ...props };

    const [currentOffset, setCurrentOffset] = useState([
        getDurationInSeconds(firstItemStartTime, visibleTimeStart),
        getDurationInSeconds(firstItemStartTime, visibleTimeEnd)]);
    const [offset, setOffset] = useState([
        getDurationInSeconds(firstItemStartTime, visibleTimeStart),
        getDurationInSeconds(firstItemStartTime, visibleTimeEnd)])

    const [duration, setDuration] = useState(0)
    const [isRangeDragging, setRangeDragging] = useState(false);
    const [timePickerChangeRequest, setTimePickerChangeRequest] = useState(false)

    const scrollTimeline = () => {
        const newStartTime = moment(firstItemStartTime).add(currentOffset[0], "seconds")
        const newEndTime = moment(firstItemStartTime).add(currentOffset[1], "seconds")

        onScrollTo(newStartTime, newEndTime)
    }

    useEffect(() => {
        if (!isRangeDragging) {
            setCurrentOffset([
                getDurationInSeconds(firstItemStartTime, visibleTimeStart),
                getDurationInSeconds(firstItemStartTime, visibleTimeEnd)])
            setOffset([
                getDurationInSeconds(firstItemStartTime, visibleTimeStart),
                getDurationInSeconds(firstItemStartTime, visibleTimeEnd)])
        }
    }, [firstItemStartTime, lastItemEndTime, visibleTimeStart, visibleTimeEnd])

    useEffect(() => {
        // forse si puo' togliere questo controllo
        if (!isRangeDragging) {
            let totalSeconds = getDurationInSeconds(firstItemStartTime, lastItemEndTime)
            setDuration(totalSeconds)
        }

    }, [visibleTimeStart, visibleTimeEnd, firstItemStartTime, lastItemEndTime])

    useEffect(() => {
        setOffset(currentOffset) // consente aggiornamento TimePicker durante il movimento dello slider
        // aggiorno la posizione della Timeline in seguito al cambio di selezione dello slider
        if (isRangeDragging || timePickerChangeRequest) 
        scrollTimeline()
        setTimePickerChangeRequest(false);
    }, [currentOffset])


    return (
        <div style={{ marginLeft: "5px", marginRight: "5px", display: "flex", flexDirection: "row", justifyContent: "center" }}>

            <TimePicker firstItemStartTime={props.firstItemStartTime}
                lastItemEndTime={props.lastItemEndTime}
                currentPositionDate={moment(props.firstItemStartTime).add(offset[0], "seconds")}
                onChange={

                    (newValue) => {
                        //console.log("New tp value:", newValue);
                        setTimePickerChangeRequest(true);
                        setCurrentOffset([moment.duration(
                            moment(newValue).diff(moment(firstItemStartTime))).asSeconds(), offset[1]])

                    }}
                initialValue={offset[0]}
                editorDown={editorDown}
            />
            <div style={{ paddingTop: "15px", paddingRight: "10px", paddingLeft: "10px", width: "82%" }}>
                <RangeSlider min={0} max={duration} value={currentOffset}
                    onRangeDragStart={() => { setRangeDragging(true) }}
                    onRangeDragEnd={() => { setRangeDragging(false) }}
                    onThumbDragStart={() => { setRangeDragging(true) }}
                    onThumbDragEnd={() => { setRangeDragging(false) }}

                    onInput={(values) => {
                        //console.log("onInput:", values);
                        setCurrentOffset(values)
                    }} />

            </div>


            <TimePicker firstItemStartTime={props.firstItemStartTime}
                lastItemEndTime={props.lastItemEndTime}
                currentPositionDate={moment(props.firstItemStartTime).add(offset[1], "seconds")}
                onChange={

                    (newValue) => {
                        //console.log("New tp value:", newValue);
                        setTimePickerChangeRequest(true);
                        setCurrentOffset([offset[0], moment.duration(
                            moment(newValue).diff(moment(firstItemStartTime))).asSeconds()])

                    }}
                initialValue={offset[1]}
                editorDown={editorDown}
            />
        </div>
    );
}

const FloatableTimelinePlayerNT = (props) => {
    const { toolbarPosition, onChangeToolbarPosition, t } = props;
    return <div style={{ border: "1px solid black", display: "flex", alignItems: "flex-start" }}>

        <TimelinePlayerNT {...props} />
        <div style={{ display: "flex", marginRight: "5px", marginTop: "5px" }}>
            {toolbarPosition != ToolbarPosition.BOTTOM &&
                <IconContext.Provider value={{ color: `#007bff`, size: "1.5em" }}>
                    <BiDockBottom onClick={() => { onChangeToolbarPosition(ToolbarPosition.BOTTOM) }} cursor="pointer" data-place="top" />
                </IconContext.Provider>
            }
            {toolbarPosition != ToolbarPosition.TOP &&
                <IconContext.Provider value={{ color: `#007bff`, size: "1.5em" }}>
                    <BiDockTop onClick={() => { onChangeToolbarPosition(ToolbarPosition.TOP) }} cursor="pointer" data-place="top" />
                </IconContext.Provider>
            }
            {/* toolbarPosition != ToolbarPosition.FLOAT &&
                <IconContext.Provider value={{ color: `#007bff`, className: "global-class-name", size: "1.3em" }}>
                    <GrEject onClick={() => { onChangeToolbarPosition(ToolbarPosition.FLOAT) }} cursor="pointer" data-place="top" data-tip={`Float`} />
                </IconContext.Provider>
        */}
        </div>

    </div>
}
const TimelinePlayer = withTranslation()(FloatableTimelinePlayerNT);
export default TimelinePlayer;