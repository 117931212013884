import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import TreeView from '@material-ui/lab/TreeView';
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io"
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import { TraceEvent } from './TimelineWatcher';
import moment from 'moment';
import { useSelector} from "react-redux";
import { selectors as BookmarksSelectors } from '../../store/slices/bookmarks'
import {getFormattedTime} from "./Utils"

const getTrackTreeView = (props, items, track) => {
  items.sort((a, b) => { return (moment(a.start_time).isBefore(moment(b.start_time)) ? -1 : 1) });
  return items.map((item, index) => {
    return (item.track != track.id ? null :
      <TreeItem nodeId={`${item.id}`}
        onLabelClick={
          () => {
            props.onEventToWatch && (props.onEventToWatch(TraceEvent.NAVIGATE_TO_ITEM,
              { "item": { "type": item["type"], "id": item["id"], "title": item["title"] } }));
            props.onItemSelected(moment(item.start_time, "DD/MM/YYYY HH:mm:ss"))
          }}

        label={
          <span style={{
            display: 'flex',
            marginTop: "10px",
            alignItems: 'space-between',
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          >
             {`${getFormattedTime(props.firstItemStartTime, item.start_time)} - ${item.title}`}
            
            {props.canEdit && !props.lockedGroups[track.id] &&
              <IconButton

                style={{ flex: "flexEnd", marginRight: "10px", verticalAlign: 'center' }}
                onClick={() => props.onItemEditRequest(item.id, null, moment(item.start_time, "DD/MM/YYYY HH:mm:ss"))}>
                <IconContext.Provider style={{ verticalAlign: 'middle' }}
                  value={{ color: `black`, size: "0.5em" }}>
                  <FaEdit data-place="top" data-tip={props.t('tl:Modifica')}
                    data-for="changeItemTooltip" />
                </IconContext.Provider>
              </IconButton>
            }

            <ReactTooltip id="changeItemTooltip" />

          </span>
        }

        key={index} />
    )
  })
}

const getBookmarksTreeView = (props, bookmarks) => {
  //bookmarks.sort((a, b) => { return (moment(a.position).isBefore(moment(b.position)) ? -1 : 1) });
  return bookmarks.map((bookmark, index) => {
     return( <TreeItem nodeId={`${index}`}
        onLabelClick={
          () => {
            props.onEventToWatch && (props.onEventToWatch(TraceEvent.NAVIGATE_TO_BOOKMARK,
              { "bookmark": bookmark }));
            props.onBookmarkSelected(moment(bookmark.position))
          }}

        label={
          <span style={{
            display: 'flex',
            marginTop: "10px",
            alignItems: 'space-between',
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          data-tip={`${bookmark.description}`}
          data-for="changeItemTooltip"
          data-type= "info"
          >
            {`${getFormattedTime(props.firstItemStartTime, bookmark.position)} - ${bookmark.title}`}
            {/*`${moment(bookmark.position).format("DD/MM/YYYY HH:mm:ss")} - ${bookmark.title}`*/}
            {
              <IconButton

                style={{ flex: "flexEnd", marginRight: "10px", verticalAlign: 'center' }}
                onClick={() => props.onBookmarkUpdateRequest(bookmark)}>
                <IconContext.Provider style={{ verticalAlign: 'middle' }}
                  value={{ color: `black`, size: "0.5em" }}>
                  <FaEdit data-place="top" data-tip={props.t('tl:Modifica')}
                    data-for="changeItemTooltip" />
                </IconContext.Provider>
              </IconButton>
            }

            <ReactTooltip id="changeItemTooltip" />

          </span>
        }

        key={index} />
    )
  })
}


function RialeItemsNavigatorNT(props) {
  
  const { tracks, items,  height, visible, currentPositionDate, onClosePanel, t } = props;
  const [value, setValue] = React.useState("");
  const bookmarks = useSelector(BookmarksSelectors.getBookmarks);
  //console.log("BMR bookmarks:", bookmarks)

 
  ////console.log("BOOKMARKS from NAV:", bookmarks)
  let filteredBookmarks = bookmarks.filter((bookmark) => {
    return (value == "" || bookmark.title?.toLowerCase().indexOf(value.toLowerCase()) >= 0 || bookmark.description?.toLowerCase().indexOf(value.toLowerCase()) >= 0)
  });
  filteredBookmarks && filteredBookmarks.sort((a, b) => { return (moment(a.position).isBefore(moment(b.position)) ? -1 : 1) });

  return (
    visible &&
    (
      <div>
        <h4>
          <span
            className="handle"
            style={{
              background:"#007bff",
              cursor: "move",
              color: "white",
              width: "100%",
              display: 'flex',
              justifyContent: 'center',
            }}>
            <b style={{ marginLeft: '10px', paddingTop: '8px', paddingBottom: '8px' }}>{t("tl:index_of_contents")}</b>
            <IconButton
              style={{
                verticalAlign: 'top', marginLeft: 'auto'
              }}

              onClick={() => { onClosePanel() }}>
              <IconContext.Provider style={{ verticalAlign: 'top' }}
                value={{ color: "white", size: "1.0em" }}>
                <IoIosCloseCircleOutline data-place="top"
                  data-for="itemNavigatorTooltip"
                  data-tip={t("tl:close_panel")}
                />
              </IconContext.Provider>
            </IconButton>
          </span>

        </h4>



        <div style={{
          display: 'flex', flexDirection: 'column',
          'overflowY': 'auto', 'height': height - 65
        }}>

          <div>
            <InputGroup>
              <Input
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                placeholder={t("ricerca_contenuti")}
              />

              <InputGroupAddon addonType="append">
                {value == "" ? (
                  <Button style={{ width: 50 }} color="primary">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ fontSize: 16 }}
                      color="white"
                    />
                  </Button>
                ) : (
                    <Button
                      style={{ width: 50 }}
                      onClick={() => {
                        //dispatch(CatalogActions.clearFilter());
                        setValue("");
                      }}
                      color="primary"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ fontSize: 16 }}
                        color="white"
                      />
                    </Button>
                  )}
              </InputGroupAddon>
            </InputGroup>
          </div>
          <TreeView

            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {

              tracks.map((track, index) => {

                const filteredItems = items.filter((item) => {
                  return item.track === track.id && (value == "" || item.title?.toLowerCase().indexOf(value.toLowerCase()) >= 0 || item.description?.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                })
                const count = filteredItems.length;
                //console.log("Valore di track", track);
                return (
                  <TreeItem nodeId={`${track.id}`} label={
                    <span style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: "10px",
                      alignItems: 'stretch'
                    }}>
                      <b> {t(track.title)} ({count}) </b>

                      {props.canEdit && !props.lockedGroups[track.id] &&
                        <IconButton
                          style={{ flex: "flexEnd", marginRight: "10px", verticalAlign: 'top' }}

                          onClick={() => props.onItemCreateRequest(track.id, currentPositionDate, null)}>
                          <IconContext.Provider style={{ verticalAlign: 'top' }}
                            value={{ color: `black`, size: "0.5em" }}>
                            <FaPlusCircle data-place="top"
                              data-for="itemNavigatorTooltip"
                              data-tip={t("tl:Aggiunta_item", { itemType: track.type })}
                            />
                          </IconContext.Provider>
                        </IconButton>
                      }

                    </span>
                  } key={index}>
                    { getTrackTreeView(props, filteredItems, track)}
                  </TreeItem>

                )
              })

            }

            {/* Bookmarks */}

            {
              <TreeItem nodeId={`bookmarks`} label={
                <span style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: "10px",
                  alignItems: 'stretch'
                }}>
                  <b> {t("Bookmarks")} ({filteredBookmarks.length}) </b>

                  {
                    <IconButton
                      style={{ flex: "flexEnd", marginRight: "10px", verticalAlign: 'top' }}

                      onClick={() => props.onBookmarkCreateRequest(currentPositionDate)}>
                      <IconContext.Provider style={{ verticalAlign: 'top' }}
                        value={{ color: `black`, size: "0.5em" }}>
                        <FaPlusCircle data-place="top"
                          data-for="itemNavigatorTooltip"
                          data-tip={t("tl:tip_addBookmark_from_nav")}
                        />
                      </IconContext.Provider>
                    </IconButton>
                  }

                </span>
              } key={"bookmarks"}>
                {
                  getBookmarksTreeView(props, filteredBookmarks)
                }
              </TreeItem>
            }
          </TreeView>

        </div>
        <ReactTooltip id="itemNavigatorTooltip" />
      </div>
    )
  );
}


const RialeItemsNavigator = withTranslation()(RialeItemsNavigatorNT);
export default RialeItemsNavigator;